import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import userProfilePicture from '../assets/img/users/user_pp.png';
import Page from '../components/Page';
import ItemOption from '../components/Widget/ItemOption';
import Global, {DD_MM_YYYY_HH_MM_SS, USER_STATUSES, VERIFICATION_STATUSES,} from '../utils/Global';
import {
    allIsEmpty,
    cleanPhoneNumber,
    deleteParam,
    isEmpty,
    isValidEmail,
    parseDate,
    sortirMap,
} from '../utils/Utilities';
import queryString from 'query-string';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import FormText from 'reactstrap/es/FormText';
import SearchInput from '../components/SearchInput';
import {UserDto} from '../model/model.ts';
import DateInput from '../components/Widget/DateInput';
import ImageCropper from '../components/modal/ImageCropper';
import {MdHistory} from 'react-icons/all';
import label, {GENDERS} from '../message/Label';
import kitchen from "../utils/AxiosInstance";
import UserStatusHistoryModal from "../components/modal/UserStatusHistoryModal";
import MyCardImage from "../components/Card/MyCardImage";

class UserDetailPage extends BasePage{

    user = new UserDto()

    constructor(props) {
        super(props);
        this.state = {
            phoneNumberWarning:"",
            user:this.user,
            photoBlob:null,
            image:null,
            userIdQuery: queryString.parse(this.props.query).id,
            gender:{},
            userStatus:{},
            modalAddConfirm:false,
            ascending:false,
            sortir:'created',
            userRoles:[],
            userRole:{},
            manufacturingSites:[],
            manufacturingSite:{},
            provinces:[],
            cities:[],
            districts:[],
            villages:[],
            province:{},
            city:{},
            district:{},
            village:{},
            inspectionStatuses:[],
            inspectionEvents:[],
            inspectionStatus:null,
            refCode:"",
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            updateVerificationStatusModal:false,
            deviceSortir:"ud.created",
            deviceSearch:"",
            deviceAscending:false,
            userStatusModal:false,


        }
        this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchUserDetail(this.state.userIdQuery)
        this.fetchAllRole()
        this.fetchManufacturingSites()
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1,
                userIdQuery: queryString.parse(props.query).id,
            }, () => {
                this.fetchUserDetail(this.state.userIdQuery)
            })
        }
    }


    confirmSave = () =>{
        let user = this.state.user
        let userRole = this.state.userRole
        // if(allIsEmpty(user.firstname, user.email, user.birthdate, user.address, user.village, user.userStatus)){
        //     this.showDialog("Notice", "Please complete the firstname")
        //     return ;
        // }

        if(allIsEmpty(user.mobilePhone)){
            this.showDialog("Invalid Input", "Please complete the mobile phone")
            return ;
        }
        if(user.mobilePhone.length<12){
            this.showDialog("Invalid Input", "Minimum character of mobile phone is 12")
            return ;
        }

        if(!isEmpty(user.homePhoneNumber)){
            if(user.homePhoneNumber.length<9){
                this.showDialog("Invalid Input", "Minimum character of phone number is 9")
                return ;
            }
        }

        if(!isEmpty(user.idCardCode)){
            if(user.idCardCode.length<16){
                this.showDialog("Invalid Input", "Minimum character of ID Card is 16")
                return ;
            }
        }
        if(allIsEmpty(user.email)){
            this.showDialog("Invalid Input", "Please complete the email")
            return ;
        }
        if(!isValidEmail(user.email)){
            this.showDialog("Invalid Input", "Email is invalid")
            return ;
        }

        if(allIsEmpty(user.firstname)){
            this.showDialog("Invalid Input", "Please complete the firstname")
            return ;
        }
        if(user.firstname.length<3){
            this.showDialog("Invalid Input", "Minimum character of firstname is 3")
            return ;
        }
        if(!userRole || !userRole.id){
            this.showDialog("Invalid Input", "Please complete the User Role")
            return ;
        }
        if(allIsEmpty(user.userStatus)){
            this.showDialog("Invalid Input", "Please complete the status")
            return ;
        }

        this.setState({
            modalAddConfirm:true
        })

    }

    async saveUpload(){
        let photoBlob = this.state.photoBlob
        if(photoBlob){
            photoBlob = await new Promise(resolve => {
                axios({
                    url: photoBlob,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.closeProgress()
                    var file = response.data ;
                    resolve(file)
                })
            })
        }
        let user = this.state.user
        user.photoUrl = null
        var formData = new FormData();
        formData.append("userDtoGson", JSON.stringify(user))
        if(photoBlob){
            formData.append("photoMultipartFile", photoBlob);
        }
        this.setState({
            modalAddConfirm:false
        }, () => {
            this.post(Global.API.USER_SAVE_UPLOAD, formData, null, res=>{
                this.setState({
                    user:res.data
                }, () => {
                    this.showDialog("Success", res.message)
                    this.fetchUserDetail(this.state.user.id)
                })
            },  true, true)
        })
    }

    generateUserCode(){
        let user = this.state.user
        this.get(Global.API.USER_GENERATE_AGENT_CODE, null, null, response=>{
            if(response.code===200){
                user.userCode = response.data
                this.setState({
                    user:user
                })
            }

        }, true, true)
    }

    fetchUserDetail = (id) => {
        if(id!=null){
            this.get(Global.API.USER, {
                params :{
                    id : id
                }
            }, null, res=>{
                if(res.code===200){
                    let user = res.data;
                    let gender = null
                    GENDERS.forEach(value => {
                        if(value.key===user.gender){
                            gender = value ;
                        }
                    })
                    let userStatus = null
                    USER_STATUSES.forEach(value => {
                        if(value.name===user.userStatus){
                            userStatus = value ;
                        }
                    })

                    this.setState({
                        user:user,
                        userRole:user.userRole,
                        manufacturingSite:user.manufacturingSite,
                        userStatus:userStatus,
                        gender:gender,
                        bank:user.bank,
                        village:user.village?user.village:null,
                        district:user.village?user.village.district:null,
                        city:user.village?user.village.district.city:null,
                        province:user.village?user.village.district.city.province:null,
                    }, () => {
                        if(user.id){
                            this.fetchUserDevices()
                        }
                        if(this.state.village){
                            this.fetchCities(this.state.province.id, cities=>{
                                this.setState({
                                    cities:cities
                                })
                            })
                        }
                        if(this.state.city){
                            this.fetchDistricts(this.state.city.id, districts =>{
                                this.setState({
                                    districts:districts
                                })
                            })
                        }
                        if(this.state.district){
                            this.fetchVillages(this.state.district.id, villages=>{
                                this.setState({
                                    villages:villages
                                })
                            })
                        }

                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true);
        }
    }

    fetchUserDevices = () => {
        let user = this.state.user ;
        this.get(Global.API.USER_DEVICES, {
            params:{
                ascending:this.state.deviceAscending,
                sortir:this.state.deviceSortir,
                search:this.state.deviceSearch,
                userId:user.id,
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    userDevices : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, true, true);
    }

    fetchAllRole = () =>{
        kitchen.get(Global.API.USER_ROLES).then(res =>{
            if(res.data.code === 200){
                this.setState({
                    userRoles : res.data.data
                })
            }
        });
    }
    fetchManufacturingSites = () =>{
        this.get(Global.API.MANUFACTURING_SITES, {
            params:{
                ascending:this.state.ascending,
                sortir:this.state.sortir
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    manufacturingSites : response.data
                })
            }
        }, true, true);
    }


    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
        })
    }

    verifyEmail = (email) =>{
        this.get(Global.API.USER_VALIDATE_EMAIL, {
            params:{
                email:email
            }
        }, null, response=>{
            if(response.code!=200){
                this.setState({
                    emailWarning:response.message
                })
            }
        }, false, true)

    }

    refreshTransaction = (ascending, sortir) => (
        this.setState({
            ascending:ascending,
            sortir:sortir,
        }, () => {
        })
    )


    openCropper(image, cropperCallback, cancelCallback){
        this.setState({
            image:image,
            imageCropperModal:true,
            cropperCallback:cropperCallback,
            cancelCallback:cancelCallback
        })
    }

    render() {
        let imageAspect = 4/4

        let userStatusModal = this.state.userStatusModal
        let user = this.state.user?this.state.user:{}
        let userDevices = this.state.userDevices?this.state.userDevices:[]
        let gender = this.state.gender?this.state.gender:{}
        let injectBalanceModalMode = this.state.injectBalanceModalMode?this.state.injectBalanceModalMode:false

        let verificationStatusColor = user.verificationStatus&&user.verificationStatus===VERIFICATION_STATUSES[0].name?"green":"#d80718"

        let canOpenMap = false ;
        //https://www.google.com/maps/dir/33.93729,-106.85761/33.91629,-106.866761/33.98729,-106.85861
        let url = "https://www.google.com/maps/dir"
        if(user.deviceCount>0 && userDevices.length>0){
            userDevices.map((item, index)=>{
                if(item.latitude && item.longitude){
                    canOpenMap = true
                    url = url+"/"+item.latitude+","+item.longitude
                }
                return item ;
            })
        }
        let evenOdd = 1 ;


        return (
            <Page
                title={label.UserDetail}
                breadcrumbs={[{ name: label.UserDetail, active: true }]}>
                {super.render()}

                <UserStatusHistoryModal
                    showing={userStatusModal} onClose={()=>{
                    this.setState({userStatusModal:false})
                }} user={user}/>

                <ConfirmDialog
                    showing={this.state.modalAddConfirm}
                    title={label.UpdateConfirmation}
                    message={label.UpdateConfirmationMessage}
                    okCallback={()=>{
                        this.showProgress()
                        this.saveUpload()
                    }}
                    cancelCallback={this.closeDialog}/>


                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader>{label.User}</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <Card>
                                            <CardHeader>
                                                {label.UserPhoto}
                                            </CardHeader>
                                            <CardBody>
                                                <MyCardImage top src={
                                                    user.photoUrl!=null?user.photoUrl:userProfilePicture}
                                                         onClick={e=>{
                                                             if(!allIsEmpty(this.state.user, this.state.user.id)) {
                                                                 this.openLightBoxSingleImage(user.photoUrl)
                                                             }
                                                         }}
                                                         onError={(elm)=>this.defaultImage(elm, userProfilePicture)}/>
                                            </CardBody>
                                            <CardBody style={{verticalAlign:'text-center'}}>
                                                {
                                                    user.id&&(
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <Button
                                                                        block
                                                                        color={"primary"}
                                                                        onClick={event => {
                                                                            this.setState({
                                                                                userStatusModal:true
                                                                            })
                                                                        }}>
                                                                        {label.StatusHistory}
                                                                        &nbsp;&nbsp;<MdHistory/>
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )
                                                }
                                                <br/>
                                                <Row style={{backgroundColor:(++evenOdd%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                    <Col md={5} style={{margin:'auto'}}>
                                                        {label.RegisteredOn}
                                                    </Col>
                                                    <Col md={1} style={{margin:'auto'}}>
                                                        :
                                                    </Col>
                                                    <Col md={6} style={{margin:'auto'}}>
                                                        {user.created?parseDate(user.created, DD_MM_YYYY_HH_MM_SS):"-"}
                                                    </Col>
                                                </Row>
                                                <Row style={{backgroundColor:(++evenOdd%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                    <Col md={5} style={{margin:'auto'}}>
                                                        {label.EverLoggedIn}
                                                    </Col>
                                                    <Col md={1} style={{margin:'auto'}}>
                                                        :
                                                    </Col>
                                                    <Col md={6} style={{margin:'auto'}}>
                                                        {
                                                            !user.firstLogin?(<span style={{color:'green'}}>{label.Yes}</span>):(<span style={{color:'red'}}>{label.Never}</span>)
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row style={{backgroundColor:(++evenOdd%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                    <Col md={5} style={{margin:'auto'}}>
                                                        {label.LastOpen}
                                                    </Col>
                                                    <Col md={1} style={{margin:'auto'}}>
                                                        :
                                                    </Col>
                                                    <Col md={6} style={{margin:'auto'}}>
                                                        {user.lastOpenDate?parseDate(user.lastOpenDate, DD_MM_YYYY_HH_MM_SS):"-"}
                                                    </Col>
                                                </Row>

                                                {
                                                    user.inspectionStatusCount&&(
                                                        Object.keys(user.inspectionStatusCount).map((key, index)=>(
                                                            <Row  style={{backgroundColor:((++evenOdd)%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                                <Col md={5} style={{margin:'auto'}}>
                                                                    {key}
                                                                </Col>
                                                                <Col md={1} style={{margin:'auto'}}>
                                                                    :
                                                                </Col>
                                                                <Col md={6} style={{margin:'auto'}}>
                                                                    {user.inspectionStatusCount[key]}
                                                                </Col>
                                                            </Row>
                                                        ))
                                                    )
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={8}>
                                        <Card>
                                            <CardBody>
                                                <Card>
                                                    <CardHeader>{label.Profile}</CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="fullname">{label.Fullname}</Label>
                                                                    <Input
                                                                        disabled
                                                                        type="text"
                                                                        name="fullname"
                                                                        value={(this.state.user!=null && this.state.user.fullname)?this.state.user.fullname:""}
                                                                        onChange={(e) =>{
                                                                            let user = this.state.user
                                                                            if(user!=null){
                                                                                user.fullname = e.target.value
                                                                                this.setState({
                                                                                    user:user
                                                                                })
                                                                            }
                                                                        }}
                                                                        placeholder={"-"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="manufacturingSite">{label.ManufacturingSite}</Label>
                                                                    <Input
                                                                        disabled
                                                                        type="text"
                                                                        name="manufacturingSite"
                                                                        value={(this.state.user!=null && this.state.user.manufacturingSite)?this.state.user.manufacturingSite.code+' - '+this.state.user.manufacturingSite.name:""}
                                                                        onChange={(e) =>{
                                                                            let user = this.state.user
                                                                            if(user!=null){
                                                                                user.lastname = e.target.value
                                                                                this.setState({
                                                                                    user:user
                                                                                })
                                                                            }
                                                                        }}
                                                                        placeholder={"-"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="userCode">{label.UserCode}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="userCode"
                                                                        value={user.userCode}
                                                                        disabled
                                                                        onChange={(e) =>{
                                                                            let user = this.state.user
                                                                            if(user!=null){
                                                                                user.userCode = e.target.value
                                                                                this.setState({
                                                                                    user:user
                                                                                })
                                                                            }
                                                                        }}
                                                                        placeholder={"-"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="email">{label.Email}</Label>
                                                                    <Input
                                                                        disabled
                                                                        type="text"
                                                                        name="email"
                                                                        value={user.email}
                                                                        onChange={(e) =>{
                                                                            let email = e.target.value
                                                                            let user = this.state.user
                                                                            if(user!=null){
                                                                                user.email = email
                                                                                this.setState({
                                                                                    user:user
                                                                                })
                                                                            }
                                                                            if(!isValidEmail(email)){
                                                                                this.setState({
                                                                                    emailWarning:label.InvalidEmailAddress
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    emailWarning:undefined
                                                                                }, () => {
                                                                                    this.verifyEmail(this.state.user.email)
                                                                                })
                                                                            }
                                                                        }}
                                                                        placeholder={"-"}
                                                                    />
                                                                    <FormText className={"font-italic"}><span style={{color:'red'}}>{this.state.emailWarning}</span></FormText>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        disable
                                                                        title={label.Role}
                                                                        objects={this.state.userRoles}
                                                                        default={this.state.userRole!=null?this.state.userRole.id:null}
                                                                        callback={(role)=>{
                                                                            if(user){
                                                                                user.userRole = role
                                                                                this.setState({
                                                                                    user:user,
                                                                                    userRole:role
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        disable
                                                                        title={label.SelectStatus}
                                                                        objects={USER_STATUSES}
                                                                        default={this.state.userStatus!=null && this.state.userStatus.id!=undefined?this.state.userStatus.id:null}
                                                                        callback={(userStatus)=>{
                                                                            let user = this.state.user
                                                                            if(user!=null && userStatus!=null){
                                                                                user.userStatus = userStatus.name
                                                                                this.setState({
                                                                                    user:user,
                                                                                    userStatus:userStatus
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="mobilePhone">{label.MobilePhone}</Label>
                                                                    <Input type="text"
                                                                           disabled
                                                                           className="form-control"
                                                                           value={user.mobilePhone}
                                                                           pattern={"[0-9]*"}
                                                                           onChange={(e)=>{
                                                                               let mobilePhone = e.target.value
                                                                               let user = this.state.user
                                                                               if(user!=null && mobilePhone!=null){
                                                                                   user.mobilePhone = cleanPhoneNumber(mobilePhone)
                                                                                   this.setState({
                                                                                       user:user
                                                                                   })
                                                                               }
                                                                           }}
                                                                           placeholder={"-"}/>
                                                                    <FormText className={"font-italic"}><span style={{color:'red'}}>{this.state.mobilePhoneWarning}</span></FormText>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="phoneNumber">{label.Phone}</Label>
                                                                    <Input type="text"
                                                                           disabled
                                                                           className="form-control"
                                                                           value={user.homePhoneNumber}
                                                                           pattern={"[0-9]*"}
                                                                           onChange={(e)=>{
                                                                               let homePhoneNumber = e.target.value
                                                                               let user = this.state.user
                                                                               if(user!=null && homePhoneNumber!=null){
                                                                                   user.homePhoneNumber = cleanPhoneNumber(homePhoneNumber)
                                                                                   this.setState({
                                                                                       user:user
                                                                                   })
                                                                               }
                                                                           }}
                                                                           placeholder={"-"}/>
                                                                    <FormText className={"font-italic"}><span style={{color:'red'}}>{this.state.phoneNumberWarning}</span></FormText>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="birthdate">{label.BirthDate}</Label>
                                                                    <DateInput
                                                                        id="birthdate"
                                                                        disabled
                                                                        maxdate={new Date()}
                                                                        value={user.birthdate}
                                                                        onChange={(e) =>{
                                                                            let value = e.target.value
                                                                            user.birthdate = value
                                                                            this.setState({
                                                                                user:user
                                                                            })
                                                                        }}
                                                                        placeholder={"-"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        disable
                                                                        title={label.SelectGender}
                                                                        objects={GENDERS}
                                                                        default={gender.id?gender.id:null}
                                                                        callback={(gender)=>{
                                                                            user.gender = gender.key
                                                                            this.setState({
                                                                                user:user,
                                                                                gender:gender
                                                                            })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <br/>
                                                <Card>
                                                    <CardHeader>
                                                        {label.Address}
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={9}>
                                                                <FormGroup>
                                                                    <Label for="address">{label.Address}</Label>
                                                                    <Input
                                                                        disabled
                                                                        type="text"
                                                                        name="address"
                                                                        value={user.address}
                                                                        onChange={(e) =>{
                                                                            let user = this.state.user
                                                                            if(user!=null){
                                                                                user.address = e.target.value
                                                                                this.setState({
                                                                                    user:user
                                                                                })
                                                                            }
                                                                        }}
                                                                        placeholder={"-"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={3}>
                                                                <FormGroup>
                                                                    <Label for="postalCode">{label.PostalCode}</Label>
                                                                    <Input
                                                                        disabled
                                                                        type="text"
                                                                        name="postalCode"
                                                                        value={(this.state.user!=null && this.state.user.village)?this.state.user.village.postalCode:""}
                                                                        placeholder={"-"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        disable
                                                                        title={label.SelectProvince}
                                                                        objects={this.state.provinces}
                                                                        default={!allIsEmpty(this.state.province)?this.state.province.id:null}
                                                                        callback={(province)=>{
                                                                            if(province!=null){
                                                                                this.setState({
                                                                                    province:province,
                                                                                    cities:[],
                                                                                    districts:[],
                                                                                    villages:[],
                                                                                }, () => {
                                                                                    this.fetchCities(province.id, cities=>{
                                                                                        this.setState({
                                                                                            cities:cities
                                                                                        })
                                                                                    })
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    province:null,
                                                                                    cities:[],
                                                                                    districts:[],
                                                                                    villages:[],
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        disable
                                                                        title={label.SelectCity}
                                                                        objects={this.state.cities}
                                                                        default={!allIsEmpty(this.state.city)?this.state.city.id:null}
                                                                        callback={(city)=>{
                                                                            if(city!=null){
                                                                                this.setState({
                                                                                    city:city,
                                                                                    districts:[],
                                                                                    villages:[],
                                                                                }, () => {
                                                                                    this.fetchDistricts(city.id, districts =>{
                                                                                        this.setState({
                                                                                            districts:districts
                                                                                        })
                                                                                    })
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    city:null,
                                                                                    districts:[],
                                                                                    villages:[],
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        disable
                                                                        title={label.SelectDistrict}
                                                                        objects={this.state.districts}
                                                                        default={!allIsEmpty(this.state.district)?this.state.district.id:null}
                                                                        callback={(district)=>{
                                                                            if(district!=null){
                                                                                this.setState({
                                                                                    district:district,
                                                                                    villages:[],
                                                                                }, () => {
                                                                                    this.fetchVillages(district.id, villages=>{
                                                                                        this.setState({
                                                                                            villages:villages
                                                                                        })
                                                                                    })
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    district:null,
                                                                                    villages:[],
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        disable
                                                                        title={label.SelectVillage}
                                                                        objects={this.state.villages}
                                                                        default={!allIsEmpty(this.state.village)?this.state.village.id:null}
                                                                        callback={(village)=>{
                                                                            if(village!=null){
                                                                                let user = this.state.user
                                                                                user.village = village
                                                                                this.setState({
                                                                                    user:user,
                                                                                    village:village,
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    !isEmpty(user.id)?
                        this.renderDevice()
                        :
                        null
                }
                <ImageCropper src={this.state.image} aspect={imageAspect} show={this.state.imageCropperModal} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        let cropperCallback = this.state.cropperCallback
                        this.setState({
                            image:file,
                            imageCropperModal:false,
                        })
                        if(cropperCallback){
                            cropperCallback(file)
                        }
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        image:null,
                        imageCropperModal:false,
                    })
                    let cancelCallback = this.state.cancelCallback
                    if(cancelCallback){
                        cancelCallback()
                    }
                }}/>

            </Page>

        );
    }


    refreshUserDevice = (ascending, sortir, progressing) => (
        this.setState({
            deviceAscending:ascending,
            deviceSortir:sortir
        }, () => {
            this.fetchUserDevices()
        })
    )
    renderDevice = () =>{
        var i = 0 ;
        let user = this.state.user
        return (
            <>
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message={label.DeleteConfirmationMessage}
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>
                <Card className="mb-6">
                    <CardHeader>
                        <strong>{label.Devices}</strong>
                        <br/><br/>
                        <Row>
                            <Col md={3}>
                                {label.SortBy} :
                                <UncontrolledButtonDropdown key={1}>
                                    <DropdownToggle
                                        caret
                                        color="white"
                                        className="text-capitalize m-1">
                                        {
                                            sortirMap(this.state.deviceSortir)
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.deviceAscending, "ud.created", true))}>{label.Created}</DropdownItem>
                                        <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.deviceAscending, "ud.updated", true))}>{label.Updated}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                            <Col md={3}>
                                {label.Sortir}&nbsp;:
                                <UncontrolledButtonDropdown key={2}>
                                    <DropdownToggle
                                        caret
                                        color="white"
                                        className="text-capitalize m-1">
                                        {
                                            this.state.deviceAscending?label.Ascending:label.Descending
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={e=>(this.refreshUserDevice(true, this.state.deviceSortir, true))}>{label.Ascending}</DropdownItem>
                                        <DropdownItem onClick={e=>(this.refreshUserDevice(false, this.state.deviceSortir, true))}>{label.Descending}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                            <Col md={6}>
                                <SearchInput
                                    placeholder={label.SearchDeviceId}
                                    value={this.state.search}
                                    onChange={e=>{
                                        this.setState({
                                            search:e.target.value
                                        }, () => {
                                            if(this.state.search===''){
                                                deleteParam(this.props, 'page')
                                            }
                                        })
                                    }}
                                    onEnter={e=>{
                                        if(this.state.search!==''){
                                            deleteParam(this.props, 'page')
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Table responsive hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{label.Platform}</th>
                                    <th>{label.IPAddress}</th>
                                    <th style={{width:'25%'}}>{label.UserAgent}</th>
                                    <th>{label.DeviceId}</th>
                                    <th>{label.Created}</th>
                                    <th>{label.LastOpen}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.userDevices&&this.state.userDevices.map((item, index)=>(
                                        <tr key={item.id}>
                                            <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                            <td>{item.platform}</td>
                                            <td>{item.ipAddress}</td>
                                            <td>{item.userAgent}</td>
                                            <td>{item.deviceId}</td>
                                            <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                            <td>{parseDate(item.lastOpenDate, DD_MM_YYYY_HH_MM_SS)}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Row>
                    </CardBody>
                </Card>
                <br/>
            </>
        );
    }
}

export default Object.assign(UserDetailPage, {ENDPOINT : "/userDetail"})

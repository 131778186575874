import React from 'react';
import {Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import label from "./../../message/Label"

export default class InspectionEventRectifierControllerSerialNumber extends BaseComponent{

    constructor(props) {
        super(props);
        let state = this.state
        state.inspection = this.props.inspection
        state.inspectionEvent = this.props.inspectionEvent
        state.rectifierControllerSerialNumbers = this.props.rectifierControllerSerialNumbers ;
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            let state = this.state
            state.inspection = props.inspection
            state.inspectionEvent = props.inspectionEvent
            state.rectifierControllerSerialNumbers = props.rectifierControllerSerialNumbers ;
            this.setState(state)
        }
    }

    render() {
        let {inspection, inspectionEvent, rectifierControllerSerialNumbers} = this.state
        return (
            <>
                {super.render()}
                <Card>
                    <CardHeader>{label.ControllerInfo}</CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{label.Controller}</Label>
                                    <Input disabled value={inspectionEvent.controller}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>{label.FWVersion}</Label>
                                    <Input disabled value={inspectionEvent.fwVersion}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <br/>
                <Card>
                    <CardHeader>{label.RectifierControllerSerialNumber}</CardHeader>
                    <CardBody>
                        <Row>
                            {
                                rectifierControllerSerialNumbers.map((value, index) => (
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>#{value.indexOrder}</Label>
                                            <Input
                                                color={"danger"}
                                                type="text"
                                                name="serialCode"
                                                disabled
                                                value={value.serialCode}
                                            />
                                        </FormGroup>
                                    </Col>
                                ))
                            }
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
}

InspectionEventRectifierControllerSerialNumber.propTypes = {
    inspection:PropTypes.object.isRequired,
    inspectionEvent:PropTypes.object.isRequired,
    rectifierControllerSerialNumbers:PropTypes.object.isRequired
}


import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {AuthConsumer} from '../../utils/AuthContext';


const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <AuthConsumer>
        {({}) =>(
            <Route
                {...rest}
                render={props => (
                    <Layout>
                        <Component {...props} query={props.location.search} />
                    </Layout>
                )
                }
            />
            )
        }
    </AuthConsumer>
);

export default PublicRoute;

import AuthForm, {STATE_LOGIN} from '../components/AuthForm';
import React from 'react';
import {Card, Col, Form, Row,
    CardBody,
    Button,
    CardHeader,CardFooter} from 'reactstrap';
import queryString from "query-string";
import BaseComponent from "../components/BaseComponent";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import ReactSpeedometer from "react-d3-speedometer"
import "react-internet-meter/dist/index.css";
import label from "../message/Label"
import {BASE_SERVER, COPYRIGHT} from "../Variable";
import {Link} from "react-router-dom";

class NetworkMeterPage extends BaseComponent {

    constructor(props) {
        super(props);
        let state = this.state
        state.message = queryString.parse(this.props.query).message
        state.netSpeed = 0
        state.run = true
        this.setState(state)
    }

    handleLogoClick = () => {
        this.props.history.push('/');
    };

    componentDidMount() {
        super.componentDidMount();
        this.setState({run:false})
    }


    render() {
        let {message, netSpeed, run} = this.state
        return (
            <span>
            <Row
                style={{
                    height: '130vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Col md={6} lg={4}>
                    <Card style={{textAlignment:'center'}}>
                        <CardHeader>{label.NetworkMeter}</CardHeader>
                        <CardBody>
                            <div className="text-center">
                                <ReactSpeedometer
                                    customSegmentStops={[0, 20, 80, 140, 200]}
                                    segmentColors={["firebrick", "tomato", "gold", "limegreen"]}
                                    paddingHorizontal={0}
                                    paddingVertical={0}
                                    width={500}
                                    fluidWidth={false}
                                    minValue={0}
                                    maxValue={200}
                                    currentValueText={run?netSpeed+' MB/second':'0 MB/second'}
                                    value={run?netSpeed:0}
                                />
                            </div>
                        </CardBody>
                        <CardFooter>
                            <Button color={run?"danger":"secondary"} size="lg" block onClick={()=>{
                                run = run===true?false:true
                                this.setState({run}, this.forceUpdate())
                            }}>
                                {run?label.StopTest:label.RunTest}
                            </Button>
                            <br/>
                            <div className="text-center">
                                {label.NetworkInfoDescriptionInfo}
                            <hr/>
                            <Link to="#" onClick={()=>{this.props.history.goBack();}}>{label.Back}</Link>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
                <ReactInternetSpeedMeter
                    txtSubHeading={"Internet is too slow " + "" + " MB/s"}
                    outputType={"empty"}
                    customClassName={null}
                    txtMainHeading="Opps..."
                    pingInterval={1000} // milliseconds
                    thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
                    threshold={8}
                    imageUrl={BASE_SERVER+"/static/img/ic_logo_abacus.png"}
                    downloadSize="144000" //bytes
                    callbackFunctionOnNetworkDown={(speed) => {
                        console.log(`Internet speed is down ${speed}`)
                        if(run===true){
                            netSpeed = speed===undefined?0:speed
                            this.setState({netSpeed}, this.forceUpdate())
                        }else{
                            netSpeed = 0
                        }
                    }}
                    callbackFunctionOnNetworkTest={(speed) => {
                        console.log(speed)
                        if(run===true){
                            netSpeed = speed===undefined?0:speed
                            this.setState({netSpeed}, this.forceUpdate())
                        }else{
                            netSpeed = 0
                        }
                    }}
                />
            </span>

        );
    }
}

export default Object.assign(NetworkMeterPage, {ENDPOINT : "/networkMeter"})

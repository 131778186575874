import * as React from 'react';
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import label from '../../message/Label'
import sucessIconImage from '../../assets/img/widget/success_icon.png';
import notDisposableIconImage from '../../assets/img/widget/non-disposable.png';
import ceIconImage from '../../assets/img/widget/ce_.png';
import icLogoGrayscaleIconImage from '../../assets/img/logo/ic_logo_abacus_grayscale.png';
import * as PropTypes from "prop-types";
import MyCardImage from "../Card/MyCardImage";

var Barcode = require('react-barcode');

export default class InspectionSuccessDialog extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            modal_backdrop: false,
            modal_nested_parent: false,
            modal_nested: false,
            backdrop: true,
            title : this.props.title,
            message : this.props.message,
            generateSerialNumber:this.props.generateSerialNumber,
            inspection:this.props.inspection?this.props.inspection:{}
        }

    }

    componentWillReceiveProps(props , nextContext) {
        if(this.props!=props && props.showing){
            this.setState({
                title : props.title,
                message : props.message,
                generateSerialNumber:props.generateSerialNumber,
                inspection:props.inspection
            })
        }
    }

    render(){
        let {inspection, generateSerialNumber} = this.state
        let model = inspection&&inspection.model?inspection.model:{}
        return (
            <Modal
                centered={true}
                size={generateSerialNumber?"lg":"md"}
                isOpen={this.props.showing}
                backdrop={true}
                style={{maxWidth: '650px', width: '100%'}}>
                <ModalHeader>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody style={{textAlign:'center'}}>
                                    {inspection.serialCode?(
                                        <>
                                            <Row>
                                                <Col md={9}>
                                                    <Row>
                                                        <Col md={9}>
                                                            <MyCardImage src={icLogoGrayscaleIconImage}/>
                                                        </Col>
                                                        <Col md={3} className={"ce-container"}>
                                                            <MyCardImage style={{width:'80%'}} src={ceIconImage}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col md={5} style={{textAlign:'right'}}>
                                                            {label.PartName} :
                                                        </Col>
                                                        <Col md={7} style={{textAlign:'left'}}>
                                                            {model.partName}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5} style={{textAlign:'right'}}>
                                                            {label.PN} :
                                                        </Col>
                                                        <Col md={7} style={{textAlign:'left'}}>
                                                            {model.pn}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5} style={{textAlign:'right'}}>
                                                            {label.ACInput} :
                                                        </Col>
                                                        <Col md={7} style={{textAlign:'left'}}>
                                                            {model.acInput}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5} style={{textAlign:'right'}}>
                                                            {label.Frequency} :
                                                        </Col>
                                                        <Col md={7} style={{textAlign:'left'}}>
                                                            {model.frequency}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5} style={{textAlign:'right'}}>
                                                            {label.ACInputCurrent} :
                                                        </Col>
                                                        <Col md={7} style={{textAlign:'left'}}>
                                                            {model.acInputCurrent}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5} style={{textAlign:'right'}}>
                                                            {label.DCOutput} :
                                                        </Col>
                                                        <Col md={7} style={{textAlign:'left'}}>
                                                            {model.dcOutput}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5} style={{textAlign:'right'}}>
                                                            {label.Polarity} :
                                                        </Col>
                                                        <Col md={7} style={{textAlign:'left'}}>
                                                            {model.polarity}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5} style={{textAlign:'right'}}>
                                                            {label.CORef} :
                                                        </Col>
                                                        <Col md={7} style={{textAlign:'left'}}>
                                                            {inspection.customerOrderNo}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={"barcode-portrait"}>
                                                        <Barcode fontSize={36} width={3.6} value={inspection.serialCode} format={"CODE128"} displayValue={true}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <MyCardImage style={{width:'10%', display:'flex', position:'absolute'}} src={notDisposableIconImage}/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            &nbsp;
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col style={{fontWeight:"bold", fontSize:"1.2rem"}}>
                                                            www.eneter-power.com
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>
                                    ):(
                                        <MyCardImage style={{width:'60%'}} src={sucessIconImage}/>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className={"d-block"}>
                    <div>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={12}>
                                        <Button
                                            block
                                            color="success"
                                            onClick={e=>{
                                                if(this.props.goToDashboardCallback!=null){
                                                    this.props.goToDashboardCallback()
                                                }
                                            }}>
                                            {label.BackToDashboard}
                                        </Button>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={6}>
                                        <Button
                                            block
                                            color="primary"
                                            onClick={e=>{
                                                if(this.props.okCallback!=null){
                                                    this.props.okCallback()
                                                }
                                            }}>
                                            {label.Ok}
                                        </Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button
                                            block
                                            color="warning"
                                            onClick={e=>{
                                                if(this.props.onClickPrint!=null){
                                                    this.props.onClickPrint()
                                                }
                                            }}>
                                            {label.PrintSerialNumber}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

InspectionSuccessDialog.propTypes = {
    showing:PropTypes.bool.isRequired,
    title:PropTypes.string.isRequired,
    message:PropTypes.string.isRequired,
    generateSerialNumber:PropTypes.bool.isRequired,
    inspection:PropTypes.object.isRequired,
    okCallback:PropTypes.func.isRequired,
    goToDashboardCallback:PropTypes.func.isRequired,
    onClickPrint:PropTypes.func.isRequired,
}

import * as React from 'react';
import BaseComponent from '../BaseComponent';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap";
import scanningBarcodeGif from '../../assets/img/logo/scanning_barcode.gif';
import modelImageDefault from '../../assets/img/banner/rack.png';
import HorizontalLine from "../Widget/HorizontalLine";
import * as PropTypes from 'prop-types';
import Global from "../../utils/Global";
import label from "../../message/Label"
import {isEmpty} from "../../utils/Utilities";
import MyCardImage from "../Card/MyCardImage";
import Image from "../Widget/Image";
import WarningLabel from "../Widget/WarningLabel";

// const serialNumberOrReferenceRef = useRef(null)

/*= (message, okCallback, cancelCallback) =>*/
export default class FindInspectionModal extends BaseComponent{


    props  = {}

    constructor(props){
        super(props);
        this.state = {
            serialNumberOrReference:"",
            model:this.props.model?this.props.model:{},
            viewFocus:"serialNumberOrReference",
            serverMessage:undefined,
        }
    }

    componentDidCatch(error, errorInfo) {
        // this.serialNumberOrReferenceRef.current.focus()
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!==props){
            this.setState({
                model:props.model?props.model:{},
                serialNumberOrReference:"",
                serverMessage:undefined,
            })
        }
    }

    findInspection=(searchMode, serialNumberOrReference)=>{
        let {model} = this.state
        if(searchMode && isEmpty(serialNumberOrReference)){
            return;
        }
        this.get(Global.API.GET_MY_INSPECTION, {
            params:{keyword:serialNumberOrReference, modelId:model.id}
        }, null, response=>{
            if(response.code===200){
                let inspection = response.data
                if(searchMode){
                    if(!inspection.id){
                        this.setState({
                            serverMessage:label.AssemblyWithThisReferenceIsNotFound,
                        })
                    }else{
                        this.props.onScanEntry(inspection)
                    }
                }else{
                    this.props.onClickNew(inspection)
                }
            }else{
                this.errorToast(response.message)
            }
        }, true, true)
    }


    render(){
        let {serialNumberOrReference, model, viewFocus, serverMessage} = this.state
        let modelCategory = model.modelCategory?model.modelCategory:{}
        return (
            /*xl-lg-sm*/
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'80%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.show}
                    backdrop={true}>
                    <Card style={{textAlign:'center'}}>
                        <CardHeader><h3>{label.ScanTheSerialNumber}</h3></CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <Card>
                                        <CardBody>
                                            <MyCardImage
                                                style={{width:'260px'}}
                                                src={model.iconPhoto?model.iconPhotoUrl:modelImageDefault}
                                                onError={(elm)=>this.defaultImage(elm, modelImageDefault)}
                                            />
                                            <HorizontalLine/>
                                            <FormGroup style={{textAlign:'left'}}>
                                                <Label>{label.SystemType}</Label>
                                                <Input disabled value={model.systemType}/>
                                            </FormGroup>
                                            <FormGroup style={{textAlign:'left'}}>
                                                <Label>{label.Category}</Label>
                                                <Input disabled value={modelCategory.name}/>
                                            </FormGroup>
                                            <FormGroup style={{textAlign:'left'}}>
                                                <Label>{label.Description}</Label>
                                                <Input type="textarea" rows={4} disabled value={model.description}/>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col>
                                            <Image style={{width:'400px'}} src={scanningBarcodeGif}/>
                                            <br/>
                                            <Label style={{fontSize:'1.1rem'}}>{label.OrEnterItManually}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Input
                                                autoFocus={viewFocus==="serialNumberOrReference"}
                                                name="serialNumberOrReference"
                                                size={28}
                                                bsSize={28}
                                                style={{fontSize:'2.2rem', textAlign:'center'}}
                                                type="text"
                                                value={serialNumberOrReference}
                                                onChange={(e) =>{
                                                    serialNumberOrReference = e.target.value?e.target.value:""
                                                    serialNumberOrReference = serialNumberOrReference.replace(/\s/g, '')
                                                    this.setState({
                                                        serialNumberOrReference:serialNumberOrReference
                                                    })
                                                }}
                                                onKeyDown={(e)=>{
                                                    if(e.key==="Tab"){
                                                        e.preventDefault()
                                                        this.setState({
                                                            viewFocus:"ok"
                                                        })
                                                    }
                                                    if(e.key==='Enter'){
                                                        e.preventDefault()
                                                        this.findInspection(true, serialNumberOrReference)
                                                    }
                                                }}
                                                placeholder={label.SerialCodeOrReferenceCode}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <WarningLabel message={serverMessage} show={serverMessage!==undefined}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col style={{textAlign:'left'}}>
                                            <Button
                                                color={"danger"}
                                                onClick={() => this.props.onClickCancel()}>
                                                {label.Close}
                                            </Button>
                                        </Col>
                                        <Col style={{textAlign:'right'}}>
                                            <Button
                                                autoFocus={viewFocus==="ok"}
                                                name={"ok"}
                                                color={"primary"}
                                                onClick={()=>{
                                                    this.findInspection(false,"")
                                                }}>
                                                {label.NewAssembly}
                                            </Button>
                                            &nbsp;
                                            <Button onClick={(e) => {
                                                e.preventDefault()
                                                this.findInspection(true, serialNumberOrReference)
                                            }}>{label.SearchAssembly}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Modal>
            </>
           )
    }
}

FindInspectionModal.propTypes = {
    show:PropTypes.bool.isRequired,
    model:PropTypes.object.isRequired,
    onScanEntry:PropTypes.func.isRequired,
    onClickNew:PropTypes.func.isRequired,
    onClickCancel:PropTypes.func.isRequired
}

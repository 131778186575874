import * as React from 'react';
import BaseComponent from '../BaseComponent';
import {Button, Card, CardBody, Col, Input, Modal, Row} from "reactstrap";
import * as PropTypes from 'prop-types';
import label from "../../message/Label"
import inspectionImage from '../../assets/img/banner/inspection.png'
import HorizontalLine from "../Widget/HorizontalLine";
import MyCardImage from "../Card/MyCardImage";
import {isEmpty, isSuccess} from "../../utils/Utilities";
import Global from "../../utils/Global";
import WarningLabel from "../Widget/WarningLabel";

/*= (message, okCallback, cancelCallback) =>*/
export default class CreateInspectionConfirmModal extends BaseComponent{

    constructor(props){
        super(props);
        this.state = {
            inspection:this.props.inspection?this.props.inspection:{},
            referenceCode:undefined,
            errorMessage:undefined,
        }
    }
    componentWillReceiveProps(props, nextContext) {
        if(this.props!==props){
            this.setState({
                inspection:props.inspection?props.inspection:{},
                referenceCode:undefined,
                errorMessage:undefined,
            })
        }
    }
    validateReference=(referenceCode, submit)=>{
        let {model,errorMessage} = this.state
        if(isEmpty(referenceCode)){
            errorMessage = label.PleaseEnterNewReferenceCode
            this.setState({errorMessage})
            return;
        }
        this.get(Global.API.VALIDATE_NEW_REFERENCE, {
            params:{referenceCode:referenceCode}
        }, null, response=>{

            if(!isSuccess(response)){
                errorMessage = response.message
                this.setState({errorMessage})
            }else{
                errorMessage = undefined
                this.setState({errorMessage}, () => {
                    if(submit){
                        this.props.onClickOk(referenceCode)
                    }
                })
            }
        }, false, false)
    }



    render(){
        let {inspection, referenceCode, errorMessage} = this.state
        let model = inspection.model?inspection.model:{}
        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    size="md"
                    centered={true}
                    isOpen={this.props.show}
                    backdrop={true}>
                    <Card style={{textAlign:'center'}}>
                        <CardBody>
                            <Row>
                                <Col>
                                    <MyCardImage style={{padding:'32px'}} style={{width:'80%'}} src={inspectionImage}/>
                                </Col>
                            </Row>
                            <HorizontalLine/>
                            <Row>
                                <Col>
                                    <h3>
                                        <strong>
                                            {model.systemType}
                                        </strong>
                                    </h3>
                                    <br/>
                                    <Input
                                        autoFocus={true}
                                        name="referenceCode"
                                        size={24}
                                        bsSize={24}
                                        style={{fontSize:'2.0rem', textAlign:'center'}}
                                        type="text"
                                        value={referenceCode}
                                        onChange={(e) =>{
                                            referenceCode = e.target.value?e.target.value:""
                                            referenceCode = referenceCode.replace(/\s/g, '')
                                            errorMessage = undefined
                                            this.setState({
                                                referenceCode:referenceCode, errorMessage
                                            }, () => {
                                                if(referenceCode.length>=Global.REFERENCE_LENGTH){
                                                    this.validateReference(referenceCode)
                                                }
                                            })
                                        }}
                                        onKeyDown={(e)=>{
                                            if(e.key==="Tab"){
                                                e.preventDefault()
                                                this.setState({
                                                    viewFocus:"ok"
                                                })
                                            }
                                            if(e.key==='Enter'){
                                                e.preventDefault()
                                                this.validateReference(referenceCode, true)
                                            }
                                        }}
                                        placeholder={label.CreateReferenceCode}
                                    />
                                    {/*<br/>*/}
                                    <WarningLabel message={errorMessage} show={errorMessage!=undefined}/>
                                    <br/>
                                    <strong style={{color:'brown'}}>
                                        {label.DoYouWantToCreateNewAssemblyPleaseEnterNewReferenceCodeAbove}
                                    </strong>
                                </Col>
                            </Row>
                            <HorizontalLine/>
                            <Row>
                                <Col style={{textAlign:'left'}}>
                                    <Button
                                        block
                                        color={"danger"}
                                        onClick={() => {
                                            this.props.onClickCancel()
                                        }}>
                                        {label.Cancel}
                                    </Button>
                                </Col>
                                <Col style={{textAlign:'right'}}>
                                    <Button
                                        block
                                        onClick={() => {
                                            this.validateReference(referenceCode, true)
                                        }}>
                                        {label.CreateNewAssembly}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Modal>
            </>
           )
    }
}

CreateInspectionConfirmModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClickOk:PropTypes.func.isRequired,
    onClickCancel:PropTypes.func.isRequired,
    inspection:PropTypes.object.isRequired,
}

import * as React from 'react'
import Typography from "../../components/Typography";

export default class WarningLabel extends React.Component{

    state : any = {

    }
    props : any = {
        message:null,
        show:false
    }

    constructor(props:any) {
        super(props);
        let state = this.state
        state.message = props.message
        state.show = props.show
        this.setState(state)
    }
    componentWillReceiveProps(props : any, nextContext : any) {
        let state = this.state
        state.message = props.message
        state.show = props.show
        this.setState(state)
    }

    render() {
        let message = this.props.message
        let show = this.props.show
        if(message && message!==''){
            return (
                <>
                    {
                        show&&(
                            <Typography type={""} tag="h6" className="text-danger" style={{marginTop:'3px', marginBottom:'3px', ...this.props.style}}>
                                <strong style={{fontSize:'13px', ...this.props.style}}>
                                    {
                                        this.state.message
                                    }
                                </strong>
                            </Typography>
                        )
                    }
                </>
            );
        }else{
            return (null)
        }
    }
}

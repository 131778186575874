import * as React from 'react';
import BaseComponent from '../BaseComponent';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    Row
} from "reactstrap";
import scanningBarcodeGif from '../../assets/img/logo/conveyor-belt.png';
import * as PropTypes from 'prop-types';
import label from "../../message/Label"
import Image from "../Widget/Image";
import {isEmpty} from "../../utils/Utilities";
import WarningLabel from "../Widget/WarningLabel";

// const serialCodeRef = useRef(null)

export default class ConfirmInspectionModal extends BaseComponent{

    constructor(props){
        super(props);
        let inspectionEvent = this.props.inspectionEvent?this.props.inspectionEvent:{}
        this.state = {
            inspectionStatusSubmitOptions:this.props.inspectionStatusSubmitOptions?this.props.inspectionStatusSubmitOptions:[{},{}],
            inspectionEvent:inspectionEvent,
            inspectionStatus : inspectionEvent.inspectionStatus?inspectionEvent.inspectionStatus:{},
            viewFocus:"newSerialCode",
            inspectionNote:"",
            newSerialCode:"",
            generateSerialNumber:isEmpty(inspectionEvent.serialCode),
        }
        this.setState(this.state)
    }

    componentDidCatch(error, errorInfo) {
        // this.serialCodeRef.current.focus()
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!==props){
            let inspectionEvent = props.inspectionEvent?props.inspectionEvent:{}
            this.setState({
                inspectionEvent:inspectionEvent,
                inspectionStatusSubmitOptions:props.inspectionStatusSubmitOptions?props.inspectionStatusSubmitOptions:[{},{}],
                inspectionStatus : inspectionEvent.inspectionStatus?inspectionEvent.inspectionStatus:{},
                inspectionNote:"",
                newSerialCode:"",
                generateSerialNumber:isEmpty(inspectionEvent.serialCode),
            })
        }
    }
    componentDidMount() {
        super.componentDidMount();
    }


    render(){
        let {inspectionNote, inspectionEvent, generateSerialNumber,
            viewFocus, inspectionStatus, inspectionStatusSubmitOptions, serialCodeWarning, inspectionStatusWarning} = this.state

        return (
            /*xl-lg-sm*/
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    size="lg"
                    centered={true}
                    isOpen={this.props.show}
                    backdrop={true}>
                    <Card style={{textAlign:'center'}}>
                        <CardHeader><h3>{label.ApplyingInspectionResult}</h3></CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Image style={{width:'400px'}} src={scanningBarcodeGif}/>
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                   {/*<FormGroup>*/}
                                   {/*    <Input*/}
                                   {/*        autoFocus={viewFocus==="newSerialCode"}*/}
                                   {/*        name="newSerialCode"*/}
                                   {/*        size={24}*/}
                                   {/*        bsSize={24}*/}
                                   {/*        style={{fontSize:'2rem', textAlign:'center', background:'white', color:'blue', fontStyle:'bold'}}*/}
                                   {/*        type="text"*/}
                                   {/*        value={newSerialCode}*/}
                                   {/*        disabled={false}*/}
                                   {/*        onKeyDown={e=>{*/}
                                   {/*            if(e.key==='Enter'){*/}
                                   {/*                e.preventDefault()*/}
                                   {/*                document.getElementById("passCheckBox").focus()*/}
                                   {/*            }*/}
                                   {/*        }}*/}
                                   {/*        onChange={(e) =>{*/}
                                   {/*            newSerialCode = e.target.value?e.target.value:*/}
                                   {/*            newSerialCode = newSerialCode.replace(/\s/g, '')*/}
                                   {/*            this.setState({*/}
                                   {/*                newSerialCode:newSerialCode,*/}
                                   {/*                serialCodeWarning:""*/}
                                   {/*            })*/}
                                   {/*        }}*/}
                                   {/*        placeholder={inspectionEvent.serialCode?inspectionEvent.serialCode:label.ScanTheSerialNumber}*/}
                                   {/*    />*/}
                                   {/*    <WarningLabel show={serialCodeWarning} message={serialCodeWarning} style={{fontSize:'18px'}}/>*/}
                                   {/*</FormGroup>*/}
                                    {label.InspectionConfirmDescription}
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <InputGroup
                                        className={"clickable"}
                                        onClick={()=>{
                                            let inspectionStatus = inspectionStatusSubmitOptions[0]
                                            this.setState({
                                                inspectionStatus:inspectionStatus,
                                                inspectionStatusWarning:""
                                            })
                                        }}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <Input
                                                    id={"passCheckBox"}
                                                    checked={inspectionStatus.id===inspectionStatusSubmitOptions[0].id}
                                                    addon type="checkbox"
                                                    aria-label={inspectionStatusSubmitOptions[0].name}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            style={{fontSize:'1.5rem', background:'white'}}
                                            disabled placeholder={inspectionStatusSubmitOptions[0].label} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup
                                        className={"clickable"}
                                        onClick={()=>{
                                            let inspectionStatus = inspectionStatusSubmitOptions[1]
                                            this.setState({
                                                inspectionStatus:inspectionStatus,
                                                inspectionStatusWarning:""
                                            })
                                        }}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <Input
                                                    id={"notPassCheckBox"}
                                                    checked={inspectionStatus.id===inspectionStatusSubmitOptions[1].id}
                                                    addon type="checkbox"
                                                    aria-label={inspectionStatusSubmitOptions[1].name}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            style={{fontSize:'1.5rem', background:'white'}}
                                            disabled placeholder={inspectionStatusSubmitOptions[1].label} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col><WarningLabel message={inspectionStatusWarning} show={inspectionStatusWarning}  style={{fontSize:'18px'}}/> </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {label.SerialNumber}
                                </Col>
                            </Row>
                            <br/>
                            <Row style={{textAlign:'center'}}>
                                <Col md={2}></Col>
                                {
                                    inspectionEvent.serialCode&&(
                                        <Col md={4}>
                                            <FormGroup>
                                                <Input
                                                    name="serialCode"
                                                    style={{fontSize:'1.5rem', textAlign:'center', background:'white'}}
                                                    value={inspectionEvent.serialCode}
                                                    disabled={true}
                                                    placeholder={label.EnterNoteIfYouNeedTo}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )
                                }
                                <Col md={isEmpty(inspectionEvent.serialCode)?8:4}>
                                    <InputGroup
                                        className={"clickable"}
                                        onClick={(e)=>{
                                            // let generateSerialNumber = e.target.value
                                            // let generateSerialNumber = !generateSerialNumber
                                            if(generateSerialNumber){
                                                generateSerialNumber = false
                                            }else{
                                                generateSerialNumber = true
                                            }
                                            this.setState({generateSerialNumber:generateSerialNumber}, () => {this.forceUpdate()})
                                        }}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <Input
                                                    id={"generateSerialNumber"}
                                                    checked={generateSerialNumber}
                                                    addon type="checkbox"
                                                    aria-label={inspectionEvent.serialCode?"Regenerate":"Generate"}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            style={{fontSize:'1.5rem', background:'white'}}
                                            disabled placeholder={inspectionEvent.serialCode?"Regenerate":"Generate"} />
                                    </InputGroup>
                                </Col>
                                <Col md={2}></Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>{label.InspectionNote}</Label>
                                        <Input
                                            name="inspectionNote"
                                            style={{fontSize:'1.5rem', textAlign:'center', background:'white'}}
                                            type="textarea"
                                            value={inspectionNote}
                                            disabled={false}
                                            onChange={(e) =>{
                                                inspectionNote = e.target.value
                                                this.setState({
                                                    inspectionNote:inspectionNote
                                                })
                                            }}
                                            placeholder={label.EnterNoteIfYouNeedTo}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign:'left'}}>
                                    <Button
                                        color={"danger"}
                                        onClick={() => this.props.onClickCancel()}>
                                        {label.Close}
                                    </Button>
                                </Col>
                                <Col style={{textAlign:'right'}}>
                                    <Button onClick={() => {
                                        // if(isEmpty(newSerialCode)){
                                        //     newSerialCode = inspectionEvent.serialCode
                                        // }
                                        // if(isEmpty(newSerialCode)){
                                        //     this.setState({
                                        //         serialCodeWarning:label.ScanTheSerialNumber
                                        //     })
                                        //     return
                                        // }
                                        if(isEmpty(inspectionStatus)){
                                            this.setState({
                                                inspectionStatusWarning:label.SelectInspectionStatus
                                            })
                                            return
                                        }

                                        this.props.onClickSubmit(generateSerialNumber, inspectionNote, inspectionStatus)
                                    }}>{label.Submit}</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Modal>
            </>
           )
    }
}

ConfirmInspectionModal.propTypes = {
    show:PropTypes.bool.isRequired,
    inspectionStatusSubmitOptions:PropTypes.array.isRequired,
    inspectionEvent:PropTypes.object.isRequired,
    onClickSubmit:PropTypes.func.isRequired,
    onClickCancel:PropTypes.func.isRequired
}

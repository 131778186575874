import * as React from 'react';
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import warningImage from '../../assets/img/widget/warning.png';
import PropTypes from 'prop-types';
import HorizontalLine from "../Widget/HorizontalLine";
import label from "../../message/Label";
import MyCardImage from "../Card/MyCardImage";


export default class ConfirmInspectionCancelModal extends React.Component{

    constructor(props ){
        super(props);
        this.state = {
            inspectionEvent : this.props.inspectionEvent?this.props.inspectionEvent:{},
            inspection : this.props.inspection?this.props.inspection:{},
            generateSerialNumber:this.props.generateSerialNumber,
        }
    }

    componentWillReceiveProps(props , nextContext) {
        if(this.props.modal!=props.showing){
            this.setState({
                inspectionEvent : props.inspectionEvent?props.inspectionEvent:{},
                inspection : props.inspection?props.inspection:{},
                generateSerialNumber:props.generateSerialNumber,
            })
        }
    }

    render(){
        let {inspectionEvent, inspection, generateSerialNumber} = this.state
        let inspectionStatus = inspectionEvent.inspectionStatus?inspectionEvent.inspectionStatus:{}
        let serialNumberView = generateSerialNumber?"N/A":inspection.serialCode?inspection.serialCode:"N/A";
        return (
            <Modal
                centered={true}
                isOpen={this.props.showing}
                backdrop={true}>
                <ModalHeader>
                    {label.ConfirmSubmission}
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody style={{textAlign:'center'}}>
                            <MyCardImage style={{width:'240px'}} src={warningImage}/>
                        </CardBody>
                    </Card>
                    <br/>
                    <div style={{textAlign:'center'}}
                         dangerouslySetInnerHTML={{__html: label.YouWillPostponeInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure(inspection.reference,
                                 serialNumberView, label.Postponed)}} />
                </ModalBody>
                <HorizontalLine/>
                <ModalBody>
                    <Row>
                        <Col>
                            <Button color="warning" onClick={e=>{
                                this.props.cancelCallback()
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className={"float-right"}
                                color="danger" onClick={e=>{
                                this.props.okCallback()
                            }}>
                                {label.YesIamSure}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}
ConfirmInspectionCancelModal.propTypes = {
    inspection:PropTypes.object.isRequired,
    inspectionEvent:PropTypes.object.isRequired,
    showing:PropTypes.bool.isRequired,
    okCallback:PropTypes.func.isRequired,
    cancelCallback:PropTypes.func.isRequired,
    generateSerialNumber:PropTypes.bool.isRequired,
}

import React from "react";
import {CardImg} from "reactstrap";
import {canUseWebP} from "../../utils/Utilities";
import LetteredAvatar from "lettered-avatar";

class MyLetteredAvatar extends React.Component{

    render() {
    let {imgSrc} = this.props
        if(imgSrc){
            if(imgSrc && imgSrc.includes("base64")){
                // Base 64 nothing to do
            }else{
                if(canUseWebP()){
                    imgSrc = imgSrc+"?webp=true"
                }else{
                    imgSrc = imgSrc+"?webp=false"
                }
            }
        }
    return(
        <LetteredAvatar
            {...this.props}
            imgSrc={imgSrc}/>
    )
    }
}

export default MyLetteredAvatar
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {AuthConsumer} from '../../utils/AuthContext';
import {isEmpty} from "../../utils/Utilities";
import PropTypes from "prop-types";


function render(rootMenu, isAuth, user, Component, Layout, props) {
    if(isAuth){
        rootMenu = isEmpty(rootMenu)?"/":rootMenu
        let menu = props.path
        let userRole = user.userRole?user.userRole:{}
        let treeMenus = userRole.treeMenus?userRole.treeMenus:[]
        let memberMenus = []
        treeMenus.forEach(value => {
            memberMenus.push(value.link)
            let subChildMenus = value.childMenus?value.childMenus:[]
            subChildMenus.forEach(value1 => {
                memberMenus.push(value1.link)
            })
        })
        let accessible = false ;
        if(rootMenu==="*"){
            accessible = true
        }else{
            memberMenus.forEach(value => {
                if(value===rootMenu){
                    accessible = true
                }
            })
        }
        if(accessible){
            return (
                <Layout {...props}>
                    <Component {...props} query={props.location.search} />
                </Layout>
            )
        }else{
            return (<Redirect to="/" />)
        }

    }else{
        return (<Redirect to="/login" />)
    }
    return isAuth
}

const ProtectedRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <AuthConsumer>
        {({ isAuth, user }) => (
            <Route
                render={props =>
                    render(rest.rootMenu, isAuth, user, Component, Layout, props)
                }
                {...rest}
            />
        )}
    </AuthConsumer>
)

ProtectedRoute.propTypes = {
    rootMenu : PropTypes.string.isRequired
}

export default ProtectedRoute

import React from "react"
import {Badge} from "reactstrap";
import BaseComponent from "../BaseComponent";
import * as PropTypes from "prop-types";
import {_POSTPONED, _CLOSED, _NOT_PASS, _PASS, _SUSPENDED, _TAKEN, INSPECTION_STATUS_COLOR} from "../../utils/Global";

class StatusIndicator extends BaseComponent{

    render() {
        let inspectionStatus = this.props.inspectionStatus

        return (
            <Badge
                style={{background:INSPECTION_STATUS_COLOR[inspectionStatus.name],
                    borderRadius: '50%',
                    width:'18px',
                    height:'18px',
                    marginLeft:'16px',
                    top:'50%',
                    bottom:'50%',
                    position:'absolute',
                    transform: 'translate(-50%, -50%)'}}>
            </Badge>
        );
    }
}
StatusIndicator.propTypes = {
    inspectionStatus:PropTypes.object.isRequired,
}

export default StatusIndicator;
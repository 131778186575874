import React from 'react';
import {Button, Card, CardBody, Col, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, Table} from 'reactstrap';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import label from '../../message/Label'
import {MdCamera, MdCameraFront, MdRefresh} from "react-icons/all";
import {isEmpty} from "../../utils/Utilities.js"
import Webcam from "react-webcam";
import MyCardImage from "../Card/MyCardImage";
import Checkbox from "../Checkbox";
import FailedDialog from "./FailedDialog";

const { htmlToText } = require('html-to-text');
const options = {
    maxSizeMB: 1,          // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: undefined,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
    useWebWorker: true,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

    // following options are for advanced users
    maxIteration: 10,       // optional, max number of iteration to compress the image (default: 10)
    // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
    fileType: ".jpg",           // optional, fileType override
    initialQuality: 0.9      // optional, initial quality value between 0 and 1 (default: 1)
}


/*= (message, okCallback, cancelCallback) =>*/
export default class CameraModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.formApplication = this.props.formApplication?this.props.formApplication:{}
        state.showing = this.props.showing
        state.resultBase64Image = ""
        state.videoConstraints = {
            width: 1920,
            height: 1080,
            facingMode: "environment" // || user
        }
        state.deviceIds = []
        state.selectedDeviceIndex = 0
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                formApplication : props.formApplication?props.formApplication:{},
                showing:props.showing,
                resultBase64Image : ""
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();
        let deviceIds = []
        if(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices){
            navigator.mediaDevices.enumerateDevices().then(mediaDevices => {
                mediaDevices.forEach((mediaDevice)=>{
                    if(mediaDevice.kind==="videoinput"){
                        deviceIds.push(mediaDevice.deviceId)
                        console.log("MEDIA DEVICE ID "+mediaDevice.deviceId)
                    }
                })
            })
        }else if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
            navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment"} }).then(function (stream) {
                // display stream on web page
                let videoTracks = stream.getVideoTracks();
                videoTracks.forEach(videoTrack => {
                    let deviceId = videoTrack.getSettings().deviceId
                    deviceIds.push(deviceId)
                    console.log("DEVICE ID "+deviceId)
                })
            });
        }
        this.setState({deviceIds:deviceIds})
    }


    render(){
        let {showing, model, resultBase64Image, videoConstraints, selectedDeviceIndex, deviceIds} = this.state
        if(deviceIds.length<=0){
            return (
                <FailedDialog
                    showing={showing && deviceIds.length<=0}
                    title={label.Warning}
                    message={label.DeviceCameraIsNotSupportedPleaseCheckYourCameraPermissionOrUseAnotherWebBrowser}
                    okCallback={()=>{
                        this.props.onClose()
                    }}
                    cancelCallback={()=>{
                        this.props.onClose()
                        // this.closeFailedDialog()
                    }}/>
            )
        }


        return (
            /*xl-lg-sm*/
            <Modal
                autoFocus={false}
                size="lg"
                centered={true}
                isOpen={showing}
                backdrop={true}
                zIndex="1">
                {super.render()}
                <ModalHeader>
                    {label.TakePicture}
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            <FormGroup>
                                <Row>
                                    {
                                        (isEmpty(resultBase64Image))?
                                            (
                                                <Col>
                                                    <Row>
                                                        <Col>{label.AvailableCameraDeviceList}</Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <Table responsive hover style={{}}>
                                                                <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{label.Label}</th>
                                                                    <th>{label.Select}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    deviceIds.map((deviceId, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index+1}</td>
                                                                            <td><Input block value={deviceId} disabled/></td>
                                                                            <td>
                                                                                <Checkbox
                                                                                    checked={selectedDeviceIndex===index}
                                                                                    onChange={
                                                                                        checked => {
                                                                                            if(checked && checked===true){
                                                                                                selectedDeviceIndex = index
                                                                                            }
                                                                                            this.setState({
                                                                                                selectedDeviceIndex:index
                                                                                            })
                                                                                        }
                                                                                    }/>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <CardBody>
                                                                    <Webcam
                                                                        audio={false}
                                                                        width={"100%"}
                                                                        height={"100%"}
                                                                        ref={(cam)=> { this.cam = cam }}
                                                                        screenshotFormat="image/jpeg"
                                                                        screenshotQuality={1}
                                                                        videoConstraints={{deviceId:deviceIds[selectedDeviceIndex]?deviceIds[selectedDeviceIndex]:undefined, ...videoConstraints}}
                                                                    />
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                            :
                                            (
                                                <Col><MyCardImage src={resultBase64Image}/></Col>
                                            )
                                    }
                                </Row>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalBody>
                    <Row>
                        <Col>
                            <Button color={"warning"} onClick={(e) => {
                                e.preventDefault()
                                this.props.onClose()
                            }}>{label.Cancel}</Button>
                        </Col>
                        <Col>
                            {
                                (isEmpty(resultBase64Image))?
                                    (
                                        <>
                                            <Button
                                                className={"float-right"}
                                                color="purple"
                                                onClick={() => {
                                                    videoConstraints.facingMode = videoConstraints.facingMode===((videoConstraints.facingMode=="user")?'environment':'user')
                                                    this.setState({
                                                        videoConstraints:videoConstraints
                                                    })
                                                }}>
                                                <MdCameraFront/>
                                            </Button>
                                            <Button
                                                style={{marginRight:'24px'}}
                                                className={"float-right"}
                                                color="primary"
                                                onClick={ e => {
                                                    e.preventDefault()
                                                    resultBase64Image = this.cam.getScreenshot();
                                                    this.setState({
                                                        resultBase64Image:resultBase64Image
                                                    })

                                                }}>
                                                <MdCamera/>
                                            </Button>

                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Button
                                                className={"float-right"}
                                                color="danger"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        resultBase64Image:undefined
                                                    })
                                                }}>
                                                <MdRefresh/>
                                            </Button>
                                            <Button
                                                className={"float-right"}
                                                style={{marginRight:'24px'}}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.props.onTakePicture(resultBase64Image)
                                                }}>
                                                {label.SetPicture}
                                            </Button>
                                        </>
                                    )
                            }
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}

CameraModal.propTypes = {
    formApplication:PropTypes.object.isRequired,
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onTakePicture:PropTypes.func.isRequired,
}

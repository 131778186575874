import React from 'react';
import {Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import label from "./../../message/Label"

export default class FormRectifierControllerSerialNumber extends BaseComponent{

    constructor(props) {
        super(props);
        let state = this.state
        state.inspection = this.props.inspection
        state.inspectionEvent = this.props.inspectionEvent
        state.rectifierControllerSerialNumbers = this.props.rectifierControllerSerialNumbers ;
        state.continueFocus = 0
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            let state = this.state
            state.inspection = props.inspection
            state.inspectionEvent = props.inspectionEvent
            state.rectifierControllerSerialNumbers = props.rectifierControllerSerialNumbers ;
            this.setState(state)
        }
    }

    render() {
        let {inspection, inspectionEvent, rectifierControllerSerialNumbers, continueFocus} = this.state
        return (
            <>
                {super.render()}
                <Card>
                    <CardHeader>{label.ControllerInfo}</CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{label.Controller}</Label>
                                    <Input
                                        autoFocus={true}
                                        id="controller"
                                        value={inspectionEvent.controller}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            inspectionEvent.controller = value
                                            this.setState({
                                                inspectionEvent : inspectionEvent
                                            })
                                        }}
                                        onKeyDown={e=>{
                                            if(e.key==='Enter'){
                                                e.preventDefault()
                                                document.getElementById("fwVersion").focus()
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>{label.FWVersion}</Label>
                                    <Input
                                        id="fwVersion"
                                        value={inspectionEvent.fwVersion}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            inspectionEvent.fwVersion = value
                                            this.setState({
                                                inspectionEvent : inspectionEvent
                                            })
                                        }}
                                        onKeyDown={e=>{
                                            if(e.key==='Enter'){
                                                e.preventDefault()
                                                document.getElementById(continueFocus).focus()
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <br/>
                <Card>
                    <CardHeader>{label.RectifierControllerSerialNumber}</CardHeader>
                    <CardBody>
                        <Row>
                            {
                                rectifierControllerSerialNumbers.map((value, index) => (
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>#{value.indexOrder}</Label>
                                            <Input
                                                id={index}
                                                row={1}
                                                line={1}
                                                color={"danger"}
                                                type="text"
                                                name="serialCode"
                                                value={value.serialCode}
                                                onKeyDown={e=>{
                                                    if(e.key==='Enter'){
                                                        e.preventDefault()
                                                        this.setState({
                                                            continueFocus:continueFocus+1
                                                        }, () => {
                                                            if(continueFocus+1<rectifierControllerSerialNumbers.length){
                                                                document.getElementById(continueFocus+1).focus()
                                                            }
                                                        })
                                                    }
                                                }}
                                                onChange={(e)=> {
                                                    let eValue = e.target.value
                                                    rectifierControllerSerialNumbers[index].serialCode = eValue
                                                    this.setState({
                                                        rectifierControllerSerialNumbers:rectifierControllerSerialNumbers
                                                    })
                                                }}/>
                                        </FormGroup>
                                    </Col>
                                ))
                            }
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
}

FormRectifierControllerSerialNumber.propTypes = {
    inspection:PropTypes.object.isRequired,
    inspectionEvent:PropTypes.object.isRequired,
    rectifierControllerSerialNumbers:PropTypes.object.isRequired
}


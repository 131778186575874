import BasePage from './BasePage';
import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Label, Row, Table} from 'reactstrap';
import {parseDate} from '../utils/Utilities';
import {IoMdEye} from 'react-icons/io';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import queryString from 'query-string';
import Pagination from '../components/Pagination';
import ItemOption from '../components/Widget/ItemOption';
import Page from '../components/Page';
import Chips from 'react-chips';

import JsonDialog from '../components/modal/JsonDialog';
import DateInput from '../components/Widget/DateInput';
import label from '../message/Label'

class UserActivityPage extends BasePage{

    constructor(props) {
        super(props);
        this.state ={
            ascending:false,
            sortir:'created',
            userActivities:[],
            userActivity:{},
            activityTypes:[],
            userChips:[],
            userFirstnames:[],
            search:"",
            creatorName:"",
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            substraction:null,
            type:null,
            createdStartDate:null,
            createdEndDate:null,
            jsonModal:false,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchTransaction(true)
        this.fetchTypes()
        this.fetchUserFirstnames()
    }

    componentWillReceiveProps(props, nextContext) {
        let currentPage = this.state.page
        let propsPage = queryString.parse(props.query).page?queryString.parse(props.query).page:1
        if(props!=this.props){
            this.setState({
                page:propsPage,
                totalPage:0,
                totalElement:0,
                pageElement:0,
            }, () => {
                this.fetchTransaction(true)
            })
        }
    }


    fetchTransaction = (progressing) =>{
        let dateRange = {}
        if(this.state.createdStartDate && this.state.createdEndDate){
            dateRange = {
                startDate:this.state.createdStartDate,
                endDate:this.state.createdEndDate,
            }
        }
        this.get(Global.API.USER_ACTIVITIES, {
            params:{
                ascending:this.state.ascending,
                sortir:this.state.sortir,
                search:this.state.search,
                userNames:this.state.userChips.toString(),
                page:this.state.page-1,
                type:this.state.type,
                substraction:this.state.substraction,
                ...dateRange
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    userActivities : response.data,
                    totalPage: response.totalPage,
                    totalElement: response.totalElement,
                    pageElement: response.pageElement,
                })
            }
        }, progressing, true)
    }


    fetchTypes = () =>{
        this.get(Global.API.USER_ACTIVITY_TYPES, null, null, response=>{
            if(response.code===200){
                this.setState({
                    activityTypes:response.data
                })
            }
        }, false, true)
    }

    fetchUserFirstnames=()=>{
        this.get(Global.API.USER_FIRSTNAMES, null, null, response=>{
            if(response.code===200){
                this.setState({
                    userFirstnames:response.data
                })
            }
        }, false, true)

    }


    render() {
        return this.renderTransaction()
    }

    renderTransaction = () =>{

        let substractions = [
            {
                id:false,
                name:"+"
            },
            {
                id:true,
                name:"-"
            }
        ]
        let activityTypes = this.state.activityTypes

        let types = []

        activityTypes.map((item, index)=>{
            Object.keys(item).forEach(function eachKey(key) {
                types.push({
                    id:key,
                    name:item[key]
                })
            })
        })

        let directions = [
            {id:true, name:'Ascending'},{id:false, name:'Descending'}
        ]
        let sortirs = [
            {id:'type', name:label.Type},{id:'quantity', name:'Quantity'},{id:'note', name:'Note'},{id:'inventory.product.name', name:'Product'},{id:'inventory.name', name:'Inventory'},{id:'creator.firstname', name:'Creator'},{id:'created', name:label.Created}

        ]
        const userActivity = this.state.userActivity
        let user = userActivity.user?userActivity.user:{}

        return (
            <Page>
                {super.render()}
                <JsonDialog json={userActivity.jsonObject} showing={this.state.jsonModal} title={userActivity.fullname+" - "+userActivity.type} okCallback={()=>{
                    this.setState({
                        jsonModal:false
                    })
                }}/>

                <Card  className="border-top-0">
                    <CardHeader>User Activity</CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}>
                                <ItemOption
                                    title={label.SelectType}
                                    objects={types}
                                    default={this.state.type!=null?this.state.type:null}
                                    callback={(type)=>{
                                        if(type!=null){
                                            this.setState({
                                                type:type.id
                                            }, () => {
                                                this.fetchTransaction(true)
                                            })
                                        }
                                    }}
                                />
                            </Col>
                            <Col md={3}>
                                <Label for="searchUser">Search User</Label>
                                <Chips
                                    value={this.state.userChips}
                                    onChange={(chips)=>{
                                        this.setState({
                                            userChips:chips
                                        }, () => {
                                            this.fetchTransaction()
                                        })
                                    }}
                                    className="cr-search-form__input"
                                    suggestions={this.state.userFirstnames}
                                    placeholder="User name..."
                                />
                            </Col>
                            <Col md={3}>
                                <Label for="createdStartDate">{label.CreatedStartDate}</Label>
                                <DateInput
                                    id="createdStartDate"
                                    value={this.state.createdStartDate}
                                    maxdate={this.state.createdEndDate}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        this.setState({
                                            createdStartDate : value?value:null
                                        }, () => {
                                            if(this.state.createdStartDate && this.state.createdEndDate){
                                                this.fetchTransaction(true)
                                            }else if(!this.state.createdStartDate && this.state.createdEndDate){
                                                this.fetchTransaction(true)
                                            }
                                        })
                                    }}
                                    placeholder={label.EnterStartDate}
                                />
                            </Col>
                            <Col md={3}>
                                <Label for="createdEndDate">{label.CreatedEndDate}</Label>
                                <DateInput
                                    id="createdEndDate"
                                    mindate={this.state.createdStartDate}
                                    value={this.state.createdEndDate}
                                    onChange={(e) =>{
                                        let previousEndDate = this.state.createdEndDate;
                                        let value = e.target.value
                                        this.setState({
                                            createdEndDate :  value?value:null
                                        }, () => {
                                            if(this.state.createdStartDate && this.state.createdEndDate){
                                                this.fetchTransaction(true)
                                            }else if(this.state.createdStartDate && !this.state.createdEndDate){
                                                this.fetchTransaction(true)
                                            }
                                        })
                                    }}
                                    placeholder={label.EnterEndDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    this.renderTransactionTable()
                                }
                            </Col>
                        </Row>
                    </CardBody>
                    <Pagination {...this.props} currentPage={this.state.page} pageCount={this.state.totalPage} key={'audit'}/>
                </Card>
            </Page>
        )
    }


    renderTransactionTable = () =>{
        return (
            <Table responsive hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{label.Type}</th>
                    <th>{label.Description}</th>
                    <th>{label.User}</th>
                    <th>{label.Created}</th>
                    <th>View</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.userActivities.map((item, index)=>(
                        <tr key={index}>
                            <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                            <td>{item.typeView}</td>
                            <td>{item.message}</td>
                            <td>{item.user.fullname}</td>
                            <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                            <td>
                                <Button color="primary" onClick={event=>{
                                    event.preventDefault()
                                    this.setState({
                                        userActivity:item,
                                        jsonModal:true
                                    })
                                }}><IoMdEye/></Button>
                            </td>

                        </tr>
                    ))
                }
                </tbody>
            </Table>
        )
    }
}

export default Object.assign(UserActivityPage, {ENDPOINT : "/userActivities"})

import React from 'react';

import {Nav, Navbar, NavItem} from 'reactstrap';
import label from '../../message/Label'

const Footer = () => {
  return (
    <Navbar style={{position:'fixed', bottom:'0'}}>
      <Nav navbar>
        <NavItem>
            2020 <span className="copyleft" style={{display:'inline-block', transform:'rotate(180deg)'}}>&copy;</span> <a href="https://abacus.co.id" target="_blank">{label.AppName}</a>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;

import React from 'react';
import userImage from '../assets/img/users/user_avatar.png';
import {UserCard} from '../components/Card';

import {Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import Page from '../components/Page';
import {AuthConsumer} from '../utils/AuthContext';
import {
    allIsEmpty,
    cleanPhoneNumber,
    imageSelector,
    isEmpty,
    isSuccess,
    isValidPhoneNumber,
    parseDate
} from '../utils/Utilities';
import {getUser} from '../utils/StorageUtil';
import Button from 'reactstrap/es/Button';
import {IoMdKey, MdUpdate} from 'react-icons/all';
import ChangePasswordDialog from '../components/modal/ChangePasswordDialog';
import ImageCropper from '../components/modal/ImageCropper';
import axios from 'axios';
import Global from '../utils/Global';
import ValidatorLabel from '../components/Widget/ValidatorLabel';
import BasePage from './BasePage';
import label, {GENDERS} from '../message/Label'
import DateInput from "../components/Widget/DateInput";
import ItemOption from "../components/Widget/ItemOption";
import WarningLabel from "../components/Widget/WarningLabel";


class ProfilePage extends BasePage{

    constructor(props) {
        super(props);
        let user = getUser()
        let state = this.state
        state.user = user
        state.fistNameWarning = null
        state.lastnameError = null
        state.changePasswordModal = false
        state.provinces = []
        state.cities = []
        state.districts = []
        state.villages = []
        let gender = {}
        GENDERS.forEach(value => {
            if(value.key===user.gender){
                gender = value ;
            }
        })
        state.gender = gender
        state.village = user.village?user.village:{}
        state.district = user.village?user.village.district:{}
        state.city = user.village?user.village.district.city:{}
        state.province = user.village?user.village.district.city.province:{}
        this.setState(state)
    }

    // componentWillReceiveProps(props, nextContext) {
    //     let user = getUser()
    //     let state = this.state
    //     state.user = user
    //     state.fistNameWarning = null
    //     state.lastnameError = null
    //     state.changePasswordModal = false
    //     this.setState(state)
    // }
    componentDidMount() {
        super.componentDidMount()
        this.fetchProvinces((provinces)=>this.setState({provinces:provinces}, () => {
            if(this.state.village && this.state.village.id){
                this.fetchCities(this.state.province.id, cities=>{
                    this.setState({
                        cities:cities
                    })
                })
            }
            if(this.state.city && this.state.city.id){
                this.fetchDistricts(this.state.city.id, districts =>{
                    this.setState({
                        districts:districts
                    })
                })
            }
            if(this.state.district && this.state.district.id){
                this.fetchVillages(this.state.district.id, villages=>{
                    this.setState({
                        villages:villages
                    })
                })
            }
        }))
    }

    updateData=(login)=>{
        let state = this.state
        state.fistNameWarning = null
        state.lastnameError = null
        let {user, gender} = state
        user.gender = gender.key

        let valid = true ;

        if(isEmpty(user.firstname) || user.firstname.length<3){
            this.setState({
                fistNameWarning:label.PleaseEnterFirstName
            })
            valid = false ;
        }

        if(isEmpty(user.email)){
            this.setState({
                emailWarning:label.PleaseEnterEmail
            })
            valid = false ;
        }

        if(isEmpty(user.mobilePhone) || !isValidPhoneNumber(user.mobilePhone)){
            this.setState({
                mobilePhoneWarning:label.PleaseEnterARightMobilePhone
            })
            valid = false ;
        }


        if(valid){
            let formData = new FormData()
            formData.append("userDtoGson", JSON.stringify(user))

            this.post(Global.API.USER_SAVE, formData, null, response=>{
                if(response.code===200){
                    this.successToast(response.message)
                    if(login){
                        login(response.data)
                        this.setState({
                            user:response.data
                        })
                    }
                }
            }, true, true)
        }



    }

    uploadPhotoProfile=(image, login)=>{
        let {user} = this.state
        let formData = new FormData()
        axios({
            url: image,
            method: 'GET',
            responseType: 'blob', // important
        }).then(async (response) => {
            var file = response.data ;
            formData.append("multipartFile", file)
            let uploadResponse = await this.asyncPost(Global.API.USER_UPLOAD_IMAGE, formData, null, true, true)
            if(isSuccess(uploadResponse)){
                // this.successToast(uploadResponse.message)
                if(login){
                    login(uploadResponse.data)
                    this.setState({
                        user:uploadResponse.data
                    })
                }
            }
        })
    }

    render() {
        let {user, gender} = this.state
        let userRole = user.userRole

        return (
            <AuthConsumer>
                {({user, logout, login, refresh})=>(
                    <Page
                        title="Profile"
                        breadcrumbs={[{ name: 'profile', active: true }]}>
                        {super.render()}
                        <ChangePasswordDialog
                            modal={this.state.changePasswordModal}
                            okCallback={(oldPassword, newPassword)=>{
                                this.setState({
                                    changePasswordModal:false
                                }, () => {
                                    if(refresh){
                                        refresh()
                                    }

                                })
                            }}
                            cancelCallback={()=>{
                                this.setState({
                                    changePasswordModal:false
                                })
                            }}
                        />
                        <Row>
                            <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <UserCard
                                                avatar={user.photoUrl?user.photoUrl:userImage}
                                                onError={(elm)=>this.defaultImage(elm, userImage)}
                                                avatarSize={150}
                                                title={user.fullname}
                                                subtitle={userRole.name}
                                                text={label.Created+" "+parseDate(user.created,'dddd, dd-mmmm-yyyy')}
                                                style={{
                                                    height: 300,
                                                }}
                                                onClickAvatar={event=>{
                                                    imageSelector(file=>{
                                                        user.photoUrl = file
                                                        this.setState({
                                                            user:user,
                                                            cropperModal:true
                                                        })
                                                    }).click()

                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                outline
                                                className="float-right"
                                                color="primary" onClick={event=>{
                                                event.preventDefault()
                                                this.updateData(login)
                                            }}>
                                                {label.UpdateProfile}&nbsp;<MdUpdate/>
                                            </Button>
                                            <Button
                                                outline
                                                style={{marginRight:'6px'}}
                                                className="float-right"
                                                color="danger"
                                                onClick={event=>{
                                                event.preventDefault()
                                                this.setState({
                                                    changePasswordModal:true
                                                })
                                            }}>
                                                {label.ChangePassword}&nbsp;<IoMdKey/>
                                            </Button>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="email">{label.Email}</Label>
                                                    <Input
                                                        type="text"
                                                        name="email"
                                                        value={(this.state.user!=null && this.state.user.email)?this.state.user.email:""}
                                                        onChange={(e) =>{
                                                            let user = this.state.user
                                                            if(user!=null){
                                                                user.email = e.target.value
                                                                this.setState({
                                                                    user:user
                                                                })
                                                            }
                                                        }}
                                                        readOnly={true}
                                                        placeholder={label.EnterEmail}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="role">{label.Role}</Label>
                                                    <Input
                                                        type="text"
                                                        name="userRole"
                                                        disabled={true}
                                                        value={userRole.name}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="firstname">{label.Firstname}</Label>
                                                    <Input
                                                        type="text"
                                                        name="firstname"
                                                        value={(this.state.user!=null && this.state.user.firstname)?this.state.user.firstname:""}
                                                        onChange={(e) =>{
                                                            let user = this.state.user
                                                            if(user!=null){
                                                                user.firstname = e.target.value
                                                                this.setState({
                                                                    user:user
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterFirstname}
                                                    />
                                                    <ValidatorLabel message={this.state.fistNameWarning}/>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label for="lastname">{label.Lastname}</Label>
                                                    <Input
                                                        type="text"
                                                        name="lastname"
                                                        value={(this.state.user!=null && this.state.user.lastname)?this.state.user.lastname:""}
                                                        onChange={(e) =>{
                                                            let user = this.state.user
                                                            if(user!=null){
                                                                user.lastname = e.target.value
                                                                this.setState({
                                                                    user:user
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterLastname}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="mobilePhone">{label.MobilePhone}</Label>
                                                    <Input type="text"
                                                           className="form-control"
                                                           value={user.mobilePhone}
                                                           pattern={"[0-9]*"}
                                                           onChange={(e)=>{
                                                               let mobilePhone = e.target.value
                                                               if(user&& mobilePhone){
                                                                   user.mobilePhone = cleanPhoneNumber(mobilePhone)
                                                                   this.setState({
                                                                       mobilePhoneWarning:"",
                                                                       user:user
                                                                   })
                                                               }
                                                           }}
                                                           placeholder={label.EnterMobilePhone}/>
                                                    <WarningLabel message={this.state.mobilePhoneWarning} show={!isEmpty(this.state.mobilePhoneWarning)}/>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="phoneNumber">{label.Phone}</Label>
                                                    <Input type="text"
                                                           className="form-control"
                                                           value={user.homePhoneNumber}
                                                           pattern={"[0-9]*"}
                                                           onChange={(e)=>{
                                                               let homePhoneNumber = e.target.value
                                                               let user = this.state.user
                                                               if(user!=null && homePhoneNumber!=null){
                                                                   user.homePhoneNumber = cleanPhoneNumber(homePhoneNumber)
                                                                   this.setState({
                                                                       user:user
                                                                   })
                                                               }
                                                           }}
                                                           placeholder={label.EnterPhoneNumber}/>
                                                    <WarningLabel message={this.state.phoneNumberWarning} show={!isEmpty(this.state.phoneNumberWarning)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="birthdate">{label.BirthDate}</Label>
                                                    <DateInput
                                                        id="birthdate"
                                                        maxdate={new Date()}
                                                        value={user.birthdate}
                                                        onChange={(e) =>{
                                                            let value = e.target.value
                                                            user.birthdate = value
                                                            this.setState({
                                                                user:user
                                                            })
                                                        }}
                                                        placeholder={label.EnterBirthDate}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <ItemOption
                                                        title={label.SelectGender}
                                                        objects={GENDERS}
                                                        default={gender.id?gender.id:null}
                                                        callback={(gender)=>{
                                                            user.gender = gender.key
                                                            this.setState({
                                                                user:user,
                                                                gender:gender
                                                            })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Card>
                                            <CardHeader>
                                                {label.Address}
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={9}>
                                                        <FormGroup>
                                                            <Label for="address">{label.Address}</Label>
                                                            <Input
                                                                type="text"
                                                                name="address"
                                                                className="text-capitalize"
                                                                value={user.address}
                                                                onChange={(e) =>{
                                                                    let user = this.state.user
                                                                    if(user!=null){
                                                                        user.address = e.target.value
                                                                        this.setState({
                                                                            user:user
                                                                        })
                                                                    }
                                                                }}
                                                                placeholder={label.EnterAddress}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="postalCode">{label.PostalCode}</Label>
                                                            <Input
                                                                type="text"
                                                                name="postalCode"
                                                                disabled={true}
                                                                value={(this.state.user!=null && this.state.user.village)?this.state.user.village.postalCode:""}
                                                                placeholder={label.PostalCode}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectProvince}
                                                                objects={this.state.provinces}
                                                                default={!allIsEmpty(this.state.province)?this.state.province.id:null}
                                                                callback={(province)=>{
                                                                    if(province!=null){
                                                                        this.setState({
                                                                            province:province,
                                                                            cities:[],
                                                                            districts:[],
                                                                            villages:[],
                                                                        }, () => {
                                                                            this.fetchCities(province.id, cities=>{
                                                                                this.setState({
                                                                                    cities:cities
                                                                                })
                                                                            })
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            province:null,
                                                                            cities:[],
                                                                            districts:[],
                                                                            villages:[],
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectCity}
                                                                objects={this.state.cities}
                                                                default={!allIsEmpty(this.state.city)?this.state.city.id:null}
                                                                callback={(city)=>{
                                                                    if(city!=null){
                                                                        this.setState({
                                                                            city:city,
                                                                            districts:[],
                                                                            villages:[],
                                                                        }, () => {
                                                                            this.fetchDistricts(city.id, districts =>{
                                                                                this.setState({
                                                                                    districts:districts
                                                                                })
                                                                            })
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            city:null,
                                                                            districts:[],
                                                                            villages:[],
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectDistrict}
                                                                objects={this.state.districts}
                                                                default={!allIsEmpty(this.state.district)?this.state.district.id:null}
                                                                callback={(district)=>{
                                                                    if(district!=null){
                                                                        this.setState({
                                                                            district:district,
                                                                            villages:[],
                                                                        }, () => {
                                                                            this.fetchVillages(district.id, villages=>{
                                                                                this.setState({
                                                                                    villages:villages
                                                                                })
                                                                            })
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            district:null,
                                                                            villages:[],
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectVillage}
                                                                objects={this.state.villages}
                                                                default={!allIsEmpty(this.state.village)?this.state.village.id:null}
                                                                callback={(village)=>{
                                                                    if(village!=null){
                                                                        let user = this.state.user
                                                                        user.village = village
                                                                        this.setState({
                                                                            user:user,
                                                                            village:village,
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <ImageCropper src={user.photoUrl} show={this.state.cropperModal} callback={file=>{
                            this.setState({
                                cropperModal:false,
                            }, () => {
                                this.uploadPhotoProfile(file, login)
                            })
                        }}/>
                    </Page>

                )}
            </AuthConsumer>
        );
    }
}

export default Object.assign(ProfilePage, {ENDPOINT : "/profile"})

import React from 'react';
import {Card, CardBody, CardHeader, Row, Col, FormGroup, Label, Input} from 'reactstrap';
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import InspectionEventFormWidget from "./InspectionEventFormWidget";
import label from "../../message/Label"

export default class InspectionEventFormLaneWidget extends BaseComponent{

    constructor(props) {
        super(props);
        let state = this.state
        state.inspection = this.props.inspection
        state.inspectionEvent = this.props.inspectionEvent
        state.formLaneApplication = this.props.formLaneApplication?this.props.formLaneApplication:{}
        state.stepFormLaneId = this.props.stepFormLaneId ;
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            let state = this.state
            state.inspection = props.inspection
            state.inspectionEvent = props.inspectionEvent
            state.formLaneApplication = props.formLaneApplication?props.formLaneApplication:{}
            state.stepFormLaneId = props.stepFormLaneId ;
            this.setState(state)
        }
    }

    render() {
        let {inspection, inspectionEvent, formLaneApplication, stepFormLaneId} = this.state
        let formApplications = formLaneApplication.formApplications?formLaneApplication.formApplications:[]
        if(stepFormLaneId!==formLaneApplication.laneId){
            return null;
        }
        return (
            <Card>
                <CardHeader>{label.Preview} {formLaneApplication.title+" "+formLaneApplication.laneId}</CardHeader>
                <CardBody>
                    <Row>
                        {
                            formApplications.map((value, index) => (
                                <InspectionEventFormWidget
                                    inspection={inspection}
                                    inspectionEvent={inspectionEvent}
                                    stepFormLaneId={stepFormLaneId}
                                    formLaneApplication={formLaneApplication}
                                    formApplication={value}/>
                            ))
                        }
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

InspectionEventFormLaneWidget.propTypes = {
    inspection:PropTypes.object.isRequired,
    inspectionEvent:PropTypes.object.isRequired,
    stepFormLaneId:PropTypes.object.isRequired,
    formLaneApplication:PropTypes.object.isRequired,
}


import * as React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import label from '../../message/Label'

export default class ConfirmDialog extends React.Component{

    state = {}
    props = {
        showing:null,
        title:null,
        message:null,
        okCallback:null,
        cancelCallback:null
    }

    constructor(props ){
        super(props);
        this.state = {
            modal: this.props.showing,
            modal_backdrop: false,
            modal_nested_parent: false,
            modal_nested: false,
            backdrop: true,
            title : this.props.title,
            message : this.props.message,
        }
        this.toggle = this.toggle.bind(this)
        this.buttonClick = this.buttonClick.bind(this)
    }

    componentWillReceiveProps(props , nextContext) {
        if(this.props.modal!=props.showing){
            this.setState({
                modal:props.showing,
                title : props.title,
                message : props.message,
            })
        }
    }

    toggle(modalType ){
        if (!modalType) {
            this.setState({
                modal: !this.state.modal,
                [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
            });
        }
    }

    buttonClick (e , button ){
        e.preventDefault()
        this.toggle('backdrop')
        if(button===label.Ok){
            if(this.props.okCallback!=null){
                this.props.okCallback()
            }
        }else{
            if(this.props.cancelCallback!=null){
                this.props.cancelCallback()
            }
        }
    }

    render(){
        return (
            <Modal
                centered={true}
                isOpen={this.state.modal}
                backdrop={true}>
                <ModalHeader>
                    {this.state.title}
                </ModalHeader>
                <ModalBody>
                    {this.state.message}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={e=>(this.buttonClick(e, label.Ok))}>
                        {label.Ok}
                    </Button>{' '}
                    <Button color="secondary" onClick={e=>(this.buttonClick(e, label.Cancel))}>
                        {label.Cancel}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

import * as React from 'react';
import BaseComponent from '../BaseComponent';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap";
import modelImageDefault from '../../assets/img/banner/rack.png';
import HorizontalLine from "../Widget/HorizontalLine";
import * as PropTypes from 'prop-types';
import Global, {DD_MM_YYYY_HH_MM_SS} from "../../utils/Global";
import label from "../../message/Label"
import {isEmpty, parseDate} from "../../utils/Utilities";
import InspectionEventFormLaneWidget from "../Widget/InspectionEventFormLaneWidget";
import InspectionEventRectifierControllerSerialNumber from "../Widget/InspectionEventRectifierControllerSerialNumber";
import MyCardImage from "../Card/MyCardImage";
import {inspectionEventsFromNative} from "../../utils/MyUtilities";

// const serialNumberOrReferenceRef = useRef(null)

/*= (message, okCallback, cancelCallback) =>*/
export default class InspectionEventDetailModal extends BaseComponent{

    constructor(props){
        super(props);
        let id = this.props.inspectionEvent?this.props.inspectionEvent.id:{}
        // let inspection = inspectionEvent.inspection?inspectionEvent.inspection:{}
        // let model = inspection.model?inspection.model:{}

        this.state = {
            id:id,
            inspectionEvent:{},
            inspection:{},
            model:{},
            inspectionEvents : [],
            stepNumber : 0
        }
    }


    componentDidCatch(error, errorInfo) {
        // this.serialNumberOrReferenceRef.current.focus()
    }

    async componentWillReceiveProps(props, nextContext) {
        if(this.props!==props && props.show){
            let id = props.inspectionEvent?props.inspectionEvent.id:{}
            // let inspectionEvent = await this.fetchInspectionEventDetail(id)
            // let inspection = inspectionEvent.inspection?inspectionEvent.inspection:{}
            // let model = inspection.model?inspection.model:{}

            this.setState({
                id:id,
                stepNumber : 0
                // inspectionEvent:inspectionEvent,
                // inspection:inspection,
                // model:model,
            }, () => {
                this.fetchInspectionEventDetail();
            })
        }
    }

    fetchInspectionEventDetail = () =>{
        let {id} = this.state
        this.get(Global.API.INSPECTION_EVENT,{
            params:{
                id:id
            }
        }, null,res=>{
            if (res.code===200){
                console.log("inspectionEvent",res.data)
                let inspectionEvent = res.data;
                let inspection = inspectionEvent.inspection?inspectionEvent.inspection:{}
                let model = inspection.model?inspection.model:{}

                this.setState({
                    inspectionEvent,
                    inspection,
                    model
                },()=>{
                    let {inspection} = this.state
                    this.fetchInspectionEvents(inspection.reference)
                })
            }
        },true,false)
    }

    fetchInspectionEvents = (reference) =>{
        this.get(Global.API.INSPECTION_EVENTS, {
            params :{
                reference: reference,
                rowLimit:2,
            }
        }, null, response =>{
            if(response.code===200){
                let inspectionEvents = inspectionEventsFromNative(response.data)
                // let inspectionEvents = response.data
                // console.log("reference",reference)
                // console.log("events",inspectionEvents)
                this.setState({
                    inspectionEvents : inspectionEvents,
                    stepNumber:0,
                })
            }
        }, true, false)
    }

    nextPage = () => {
        let {stepNumber, inspectionEvents, modeRectifierControllerSerialNumbers,inspectionEvent} = this.state
        // let currentInspectionEvent = inspectionEvents.length>0?inspectionEvents[0]:{}
        let currentInspectionEvent = inspectionEvent
        let formLaneApplications = currentInspectionEvent.formLaneApplications?currentInspectionEvent.formLaneApplications:[]
        if(modeRectifierControllerSerialNumbers){
            // currentInspectionEvent.inspectionStatus = {}
            this.setState({
                confirmInspectionModal:true,
                currentInspectionEvent:currentInspectionEvent
            })
            return;
        }

        let formLaneApplication = formLaneApplications[stepNumber];
        let formApplications = formLaneApplication.formApplications?formLaneApplication.formApplications:[]
        if(stepNumber>=formLaneApplications.length-1){
            this.setState({
                modeRectifierControllerSerialNumbers:true
            })
            return
        }
        stepNumber = stepNumber+1;

        this.setState({
            stepNumber : stepNumber,
        })
    }

    previousPage = () => {
        let {stepNumber, modeRectifierControllerSerialNumbers} = this.state
        if(modeRectifierControllerSerialNumbers){
            this.setState({
                modeRectifierControllerSerialNumbers:false,
                stepNumber : stepNumber,
            })
            return;
        }
        if(stepNumber<=0){
            return
        }
        stepNumber = stepNumber-1;
        this.setState({
            modeRectifierControllerSerialNumbers:false,
            stepNumber : stepNumber,
        })
    }


    render(){
        let {inspectionEvent, inspection, model, viewFocus,
            inspectionEvents, stepNumber, modeRectifierControllerSerialNumbers} = this.state
        let modelCategory = model.modelCategory?model.modelCategory:{}

        let currentInspectionEvent = inspectionEvents.length>0?inspectionEvents[0]:{}
        let latestInspectionEvent = inspectionEvent
        let currentInspectionStatus = currentInspectionEvent.inspectionStatus?currentInspectionEvent.inspectionStatus:{}
        let currentInspectionUser = currentInspectionEvent.user?currentInspectionEvent.user:{}

        let previousInspectionEvent = inspectionEvents.length>1?inspectionEvents[1]:{}
        let previousInspectionStatus = previousInspectionEvent.inspectionStatus?previousInspectionEvent.inspectionStatus:{}
        let previousInspectionUser = previousInspectionEvent.user?previousInspectionEvent.user:{}


        let formLaneApplications = inspectionEvent.formLaneApplications?inspectionEvent.formLaneApplications:[]

        return (
            /*xl-lg-sm*/
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'90%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.show}
                    backdrop={true}>
                    <Card>
                        <CardHeader><h3>{label.InspectionDetail}</h3></CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4} style={{textAlign:'left'}}>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <div style={{textAlign:'center'}}>
                                                        <MyCardImage
                                                            style={{width:'80%'}}
                                                            src={model.iconPhoto?model.iconPhotoUrl:modelImageDefault}
                                                            onError={(elm)=>this.defaultImage(elm, modelImageDefault)}
                                                        />
                                                    </div>
                                                    <HorizontalLine/>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.Reference}</Label>
                                                                <Input disabled type="text" name="title" value={inspection.reference}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.PreviousInspectionPerformedBy}</Label>
                                                                <Input disabled type="text" name="title" value={previousInspectionUser.fullname}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.PreviousInspectionStatus}</Label>
                                                                <Input
                                                                    className={"inspection-status-"+(previousInspectionStatus.name?previousInspectionStatus.name.toLowerCase():"")}
                                                                    disabled type="text" name="title" value={previousInspectionStatus.label}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    {
                                                        !isEmpty(currentInspectionUser)&&(
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label for="name">{label.LastInspectionPerformedBy}</Label>
                                                                        <Input disabled type="text" name="title" value={currentInspectionUser.fullname?currentInspectionUser.fullname:"-"}/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.CurrentInspectionStatus}</Label>
                                                                <Input
                                                                    className={"inspection-status-"+(currentInspectionStatus.name?currentInspectionStatus.name.toLowerCase():"")}
                                                                    disabled type="text" name="title" value={currentInspectionStatus.label?currentInspectionStatus.label:"-"}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.LastInspectionStatusDate}</Label>
                                                                <Input disabled type="text" name="title" value={currentInspectionEvent.created?parseDate(currentInspectionEvent.created, DD_MM_YYYY_HH_MM_SS):"-"}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <a href={"/inspectionDetail?id="+inspection.id}>
                                                <Button color={"primary"} block>
                                                    {label.InspectionDetail}
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                    <br/>
                                    {
                                        latestInspectionEvent.canContinue&&(
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Button block onClick={() => {
                                                            this.props.onContinueInspection(inspectionEvent)
                                                        }}>
                                                            {label.ContinueInspection}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <br/>
                                            </>
                                        )
                                    }
                                    <Row>
                                        <Col>
                                            <Button block color={"danger"} onClick={(e) => {
                                                e.preventDefault()
                                                this.props.onClose()
                                            }}>
                                                {label.Close}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader>{label.Model}</CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Label>{label.SystemType}</Label>
                                                        <Input disabled type="text" name="sistemType" value={model.systemType}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{label.ModelName}</Label>
                                                        <Input disabled type="text" name="modelName" value={model.modelName}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{label.Category}</Label>
                                                        <Input disabled type="text" name="category" value={modelCategory.name}/>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader>{label.CustomerInfo}</CardHeader>
                                                    <CardBody>
                                                        <FormGroup>
                                                            <Label>{label.CustomerOrderNo}</Label>
                                                            <Input disabled type="text" name="customerOrderNo" value={inspection.customerOrderNo}/>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>{label.FWVersion}</Label>
                                                            <Input disabled type="text" name="fwVersion" value={inspection.fwVersion}/>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>{label.ProductSKU}</Label>
                                                            <Input disabled type="text" name="fwVersion" value={inspection.productSKU}/>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>{label.ProductName}</Label>
                                                            <Input disabled type="text" name="fwVersion" value={inspection.productName}/>
                                                        </FormGroup>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <HorizontalLine/>
                                    <Row>
                                        <Col>
                                            <Button
                                                color={stepNumber==0 && !modeRectifierControllerSerialNumbers?"disabled":"warning"}
                                                className={"float-left"}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.previousPage()
                                                }}>
                                                {label.PagePrevious}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                color={modeRectifierControllerSerialNumbers?"disabled":"primary"}
                                                className={"float-right"}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.nextPage()
                                                }}>
                                                {label.PageNext}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <HorizontalLine/>
                                    <br/>
                                    <Row>
                                        <Col>
                                            {
                                                modeRectifierControllerSerialNumbers?
                                                    (
                                                        <InspectionEventRectifierControllerSerialNumber
                                                            inspection={inspectionEvents}
                                                            inspectionEvent={inspectionEvent}
                                                            rectifierControllerSerialNumbers={inspectionEvent.rectifierControllerSerialNumbers}/>
                                                    )
                                                    :
                                                    (
                                                        <InspectionEventFormLaneWidget
                                                            inspection={inspection}
                                                            inspectionEvent={inspectionEvent}
                                                            stepFormLaneId={formLaneApplications[stepNumber]?formLaneApplications[stepNumber].laneId:0}
                                                            formLaneApplication={formLaneApplications[stepNumber]?formLaneApplications[stepNumber]:{}}/>
                                                    )
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Modal>
            </>
           )
    }
}

InspectionEventDetailModal.propTypes = {
    show:PropTypes.bool.isRequired,
    inspectionEvent : PropTypes.object.isRequired,
    onContinueInspection:PropTypes.func.isRequired,
    onClose:PropTypes.func.isRequired
}
